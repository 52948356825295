import React from "react";
import onlineclass from "../../src/images/onlineclass.jpeg";
// import collage from '../image/collage.png'
import SS from '../../src/images/ss.jpeg';
import singleClassBg from '../../src/images/onlineSingleClass.png'
import MetaData from "../component/layout/MetaData";
function OnlineSingleClass() {
  return (
    <>
    <MetaData title="Online Classes Subpage" />
    <div className="container-fluid">
      <div className="wisdomimg " style={{ backgroundImage: `url(${singleClassBg})`, backgroundSize: "cover" }}>
				<div className="container onlineClass wisdompad ">
					<div className="row">
						<div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-center ">
							<h1 className="fw-bold"><span style={{ fontSize: '4.5rem', color: 'black' }}> Class Details</span></h1>
						</div>
					</div>
				</div>
			</div>
      
     
      <div className="container my-3">
        <div className="row">
          <div className="col-md-9 col-12">
            <h2 className="fw-bold py-2 fs-2">Pure Strong Yoga</h2>
            <img loading="lazy" src={onlineclass} alt="Dr. Archika Didi" className="img-fluid mt-3 " />
          </div>
          <div className="col-md-3 col-12">
            <p className="fw-bold fs-2" style={{ color: "#ff9933" }}>₹ 388</p>
            <div className="">
              <button className="btn btn-lg" style={{ borderRadius: "5px", backgroundColor: "#ff9933", color: "white", fontSize: "0.7rem" }} type="button">ENROLL NOW</button>
              <div>
                <div className="d-flex mt-4">
                  <img loading="lazy" className="rounded float-start" style={{ width: "50px" }} src={SS} alt="Dr. Archika Didi" />
                  <div className="mt-1">
                    <h6 >Trainer</h6>
                    <p className="fw-lighter mb-1" style={{ fontSize: "10px" }}>Samatha Dave, Lily Carter</p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <i className="fa-solid fa-star" style={{ color: "#edf019" }}></i>
                  <i className="fa-solid fa-star" style={{ color: "#edf019" }}></i>
                  <i className="fa-solid fa-star" style={{ color: "#edf019" }}></i>
                  <i className="fa-solid fa-star" style={{ color: "#edf019" }}></i>
                  <p className="ms-2" style={{ fontSize: "12px" }}>(2 Ratings)</p>
                </div>
              </div>
              <div>
                <h6>Categories</h6>
                <p className="fw-light" style={{ fontSize: "12px" }}>Flow, Meditation</p>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12">
            <p className="pt-3 text-dark display-2 fs-3 text-start lh-base">
              Yoga is a physical, mental, and spiritual practice or discipline.
              There is a broad variety of schools, practices and goals in
              Hinduism, Buddhism (including Vajrayana and Tibetan Buddhism and
              Jainism). The best-known are Hatha yoga and Raja yoga.
            </p>

            <h3 className="fw-bold">Intoruction to the class</h3>
            <p>
              The origins of Yoga have been speculated to date back to pre-Vedic
              Indian traditions, but most likely developed around the sixth and
              fifth centuries BCE, in ancient India's ascetic circles, which are
              also credited with the early sramana movements. The chronology of
              earliest texts describing yoga-practice is unclear, varyingly
              credited to Hindu Upanishads and Buddhist Pali Canon, probably of
              third century BCE or later.
            </p>
            <p>According to Jacobsen, "Yoga has five principal meanings":</p>
            <ul className="container mx-2 text-muted">
              <li>Yoga as a disciplined method for attaining a goal;</li>
              <li>Yoga as techniques of controlling the body and the mind;</li>
              <li>
                Yoga as a name of one of the schools or systems of philosophy
                (dardana);
              </li>
              <li>
                Yoga in connection with other words, such as "hatha-, mantra-, and
                laya-," referring to traditions specialising in particular
                techniques of yoga;
              </li>
            </ul>

            <h3 className="fw-bold py-2">Schedule and preparation for class</h3>
            <p>
              Yoga came to the attention of an educated western public in the
              mid-19th century along with other topics of Indian philosophy. In
              the context of this budding interest, N.C. Paul published his
              Treatise on Yoga Philosophy in 1851.
            </p>
            <p>
              The first Hindu teacher to actively advocate and disseminate aspects
              of yoga to a western audience, Swami
            </p>

            <div className="row">
              <img loading="lazy" className="col-md-4 col-12 mb-2 d-sm-5" src={onlineclass} alt="Dr. Archika Didi" />
              <img loading="lazy" className="col-md-4 col-12 mb-2 d-sm-5" src={onlineclass} alt="Dr. Archika Didi" />
              <img loading="lazy" className="col-md-4 col-12 mb-2" src={onlineclass} alt="Dr. Archika Didi" />
            </div>

            <div className="row">
              <img loading="lazy" className="col-md-6 col-12 mb-2 d-sm-5" src={onlineclass} alt="Dr. Archika Didi" />
              <img loading="lazy" className="col-md-6 col-12 mb-2 d-sm-5" src={onlineclass} alt="Dr. Archika Didi" />
            </div>

          </div>


        </div>
      </div>
    </div>
    </>
  );
};

export default OnlineSingleClass;
