import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSinglelistBhagwat,
  getVersesBhagwat,
} from "../Allslices/BhagwatGitaSlice";
import Moment from "react-moment";
import OrderModal from "../Payments/OrderModal";
import { convertToMins } from "../appUtils";
import MetaData from "../component/layout/MetaData";
import rect74 from "../images/Rectangle74.png";
import ad from "../images/ad.png";
import { getSingleWellnessArticle } from "../Allslices/wellnessSlice";

const WellnessSingleArticle = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const { singlewellnessarticle } = useSelector((state) => state.allwellness);
  useEffect(() => {
    dispatch(getSingleWellnessArticle(slug));
  }, []);

  const audioRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (singlewellnessarticle && audioRef.current) {
      audioRef.current.src = singlewellnessarticle.audiofile;
    }
  }, [singlewellnessarticle]);

  const handlePlay = () => {
    audioRef.current.play();
    setPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setPlaying(false);
  };

  const handleTogglePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (time) => {
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleBackward = () => {
    handleSeek(currentTime - 10); // Adjust backward duration as needed
  };

  const handleForward = () => {
    handleSeek(currentTime + 10); // Adjust forward duration as needed
  };
  

  return (
    <>
      <MetaData title="Cart 1" />

      {/* banner section */}
      
      {/* Checking Purpose */}
      <div
        className=" blogimg mb-2"
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(249, 200, 124, 0.3), rgba(249, 200, 123, 21)), url(${singlewellnessarticle?.thumbnail_image})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container blogsub">
          <div className="row text-center p-5">
            <div className="col-lg-12 col-sm-12">
              <h1 className=" text-dark">{singlewellnessarticle?.title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="imagesee pt-5 mt-5">
        <img
          src={singlewellnessarticle?.thumbnail_image}
          alt="Dr. Archika Didi"
          className=""
          style={{
            borderRadius: "10px",
            boxShadow: "rgb(0, 0, 0) 1px 1px 17px 2px",
          }}
        />
      </div>

      {/* banner section end */}
      {/* text section all */}
      <section>
        <div className="container">
          <div className="textrt ">
            <div
              className="contyu text-center"
              style={{ fontSize: "20px", padding: "35px 0 35px 0" }}
              dangerouslySetInnerHTML={{
                __html: singlewellnessarticle?.content,
              }}
            ></div>
          </div>
        </div>
      </section>

     
      <br />
      <br />
    </>
  );
};

export default WellnessSingleArticle;
