import React from "react";
import nodata from "../../images/No-Data.png";

const PageNotFound = () => {
  return (
    <div>
      <section>
        <div className="container">
          <div className="text-center my-5 py-4">
            <img loading="lazy" style={{ width: "400px" }} src={nodata} alt="Dr. Archika Didi" />
            <h1 style={{fontFamily: "myFont", fontSize: "3.8rem"}}>404! Page Not Found... 🤷‍♂️🤦‍♂️</h1>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageNotFound;
