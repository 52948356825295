import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { SHOW_ERROR_NOTIFICATION } from "../../appUtils/appToast";
import "../../component/auth/auth.css";
import "../../component/layout/style.css";

const AddFileModal = ({ open, onHide, inputFiles, onSubmit, longerMedia }) => {
  const [selectedType, setSelectedType] = useState("audio");
  const [selectedFiles, setSelectedFiles] = useState({
    audio: null,
    video: null,
    image: [],
  });

  const changeType = (type) => {
    setSelectedType(type);
  };

  useEffect(() => {
    if (inputFiles) {
      setSelectedFiles({ ...inputFiles });
    }
  }, []);

  const onSave = useCallback(() => {
    onSubmit?.(selectedFiles);
    onHide();
  }, [selectedFiles]);

  const onFileChange = (event) => {
    // Update the state
    if (event.target.name != "image") {
      const file = event.target.files[0];

      var fileElem = document.createElement(event.target.name);
      fileElem.preload = "metadata";
      fileElem.onloadedmetadata = () => {
        window.URL.revokeObjectURL(fileElem.src);
        let duration = fileElem.duration;

        let maxDuration = longerMedia ? 120 : 60;
        let maxText = longerMedia ? "2" : "1";
        if (duration > maxDuration) {
          SHOW_ERROR_NOTIFICATION(`Maximum duration is ${maxText} min!`);
          return;
        }

        setSelectedFiles({ ...selectedFiles, [event.target.name]: file });
      };
      fileElem.src = URL.createObjectURL(file);
    } else {
      if (event.target.files.length + selectedFiles.image.length > 2) {
        SHOW_ERROR_NOTIFICATION("Maximum 2 images allowed!");
        return;
      }
      setSelectedFiles({
        ...selectedFiles,
        [event.target.name]: [...event.target.files],
      });
    }
  };

  const removeFile = (type, name) => {
    if (type != "image") {
      setSelectedFiles({ ...selectedFiles, [type]: null });
    } else {
      selectedFiles.image = selectedFiles.image.filter(
        (item) => item.name != name
      );
      setSelectedFiles({ ...selectedFiles });
    }
  };

  // let tabBody = null;

  const tabBody = (() => {
    switch (selectedType) {
      case "audio":
        return (
          <>
            <input
              type="file"
              name="audio"
              onChange={onFileChange}
              id="upload"
              accept="audio/*"
              hidden
            />
            <label className="btn  modalButtons" htmlFor="upload">
              Upload Audio
            </label>
            <div style={{ height: "3rem" }} />
            {selectedFiles.audio && (
              <div className="file-item">
                <label>{selectedFiles.audio.name}</label>
                <div style={{ width: "6px" }} />
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => removeFile("audio")}
                ></i>
              </div>
            )}
          </>
        );
        break;
      case "video":
        return (
          <>
            <input
              type="file"
              name="video"
              onChange={onFileChange}
              id="upload"
              accept="video/*"
              hidden
            />
            <label className="btn  modalButtons" htmlFor="upload">
              Upload Video
            </label>
            <div style={{ height: "3rem" }} />
            {selectedFiles.video && (
              <div className="file-item">
                <label>{selectedFiles.video.name}</label>
                <div style={{ width: "6px" }} />
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => removeFile("video")}
                ></i>
              </div>
            )}
          </>
        );
        break;
      case "image":
        return (
          <>
            <input
              type="file"
              name="image"
              onChange={onFileChange}
              id="upload"
              accept="image/*"
              multiple
              hidden
            />
            <label className="btn  modalButtons" htmlFor="upload">
              Upload Images
            </label>
            <div
              className="d-flex flex-row justify-content-center pt-2 gap-2"
              style={{ height: "10rem", width: "100%" }}
            >
              {selectedFiles.image &&
                selectedFiles.image.map((item, index) => (
                  <div key={item.name} className="file-item position-relative">
                    <img
                      loading="lazy"
                      src={URL.createObjectURL(item)}
                      alt={`Image ${index + 1}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <i
                      className="fa-solid fa-xmark position-absolute top-0 start-0"
                      style={{ backgroundColor: "black", borderRadius: "50%" }}
                      onClick={() => removeFile("image", item.name)}
                    ></i>
                  </div>
                ))}
            </div>
          </>
        );
      default:
        break;
    }
  })();

  return (
    open && (
      <Modal
        show={open}
        onHide={onHide}
        dialogClassName="CommunityModalBackground"
      >
        <div className="modal-content CommunityModalContent d-flex justify-content-center ">
          <div className=" modalHeader">
            {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
            <div className="topButttons">
              <button
                type="button"
                onClick={onHide}
                className="btn-close modalCloseButton"
              ></button>
            </div>
            <div className="headerListButtons">
              <ul className="p-0">
                <button
                  className="text-dark"
                  onClick={() => changeType("audio")}
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    borderBottom:
                      selectedType == "audio" ? "2px solid #B767A2" : null,
                  }}
                >
                  <span style={{ fontSize: "16px" }}> Audio</span>
                </button>
                <button
                  className="text-dark"
                  onClick={() => changeType("video")}
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    borderBottom:
                      selectedType == "video" ? "2px solid #B767A2" : null,
                  }}
                >
                  <span style={{ fontSize: "16px" }}>Video</span>
                </button>
                <button
                  className="text-dark"
                  onClick={() => changeType("image")}
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    borderBottom:
                      selectedType == "image" ? "2px solid #B767A2" : null,
                  }}
                >
                  <span style={{ fontSize: "16px" }}>Image</span>
                </button>
              </ul>
            </div>
          </div>
          <div
            className="modal-body CommunityModalBody"
            style={{ textAlign: "center" }}
          >
            {tabBody}
          </div>
          <div className="modal-footer">
            {/* <button type="button" className="btn text-secondary btn-white " onClick={onHide} >Close</button> */}
            <button
              type="button"
              onClick={onSave}
              className="btn  modalButtons"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    )
  );
};

export default AddFileModal;
