import React from 'react';
import PropTypes from 'prop-types';

const CourseCards = React.memo(({ item, onClick, renderPrice }) => (
  <div
    className="col-md-12 col-sm-12 col-lg-12 mb-3"
    onClick={() => onClick(item)}
  >
    <div className="card item-card card-block mx-4 my-3 overflow-hidden coursecards">
      {item?.sliderImages?.map((slider_image, idx) => (
        <img key={idx} src={slider_image?.course_image} alt={item.title} />
      ))}
      <div className="container courseapixoss">
        <h4 className="mt-3">
          {item.title.length > 40
            ? `${item.title.substring(0, 40)}...`
            : item.title}
        </h4>
        <p
          className="card-text lato my-2 coursecarddes"
          dangerouslySetInnerHTML={{
            __html:
              item.shortDescription.length > 80
                ? `${item.shortDescription.replace(/<\/?p>/gi, "").substring(0, 80)}...`
                : item.shortDescription.replace(/<\/?p>/gi, ""),
          }}
        ></p>
        <div className="coursecolor">
          <i className="fa-solid fa-video fs-6 pt-1 text-info" /> Online
          <i className="fa-solid fa-language fs-6 pt-1 ms-2 text-info" />{" "}
          {item.courseLanguage}
        </div>
        <div className="my-3 d-flex justify-content-between align-items-center">
          <button type="button" className="btn btn-lg d-sm-5 fs-6 courseregisterbtn">
            Register
          </button>
          {item?.coming_soon==false &&(
          <span className="d-flex flex-column coursecolor">
            {item.selling_price_rupee > 0
              ? renderPrice(
                  item.selling_price_rupee,
                  item.selling_price_usd,
                  item?.mrp_price_rupee,
                  item.mrp_price_usd
                )
              : "Free"}
          </span>
          )}
        </div>
      </div>
    </div>
  </div>
));

CourseCards.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  renderPrice: PropTypes.func.isRequired,
};

export default CourseCards;
