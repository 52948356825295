import React from "react";
import rect74 from "../images/Rectangle74.png";
import rect22 from "../images/Rectangle22.png";
import OrderModal from "../Payments/OrderModal";
import MetaData from "../component/layout/MetaData";
const Cart2 = () => {
  return (
    <>
    
      <MetaData title="Cart 2" />
      <div
        className="container-fluid pt-5"
        style={{
          //   fontFamily: "myfont",
          backgroundImage: `url(${rect74})`,
          backgroundPosition: "center right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "70vh",
        }}
      >
        <div className="container d-flex  align-items-center flex-column pt-5 lh-lg">
          <h1
            style={{ fontFamily: "playfair" }}
            className="text-uppercase text-center"
          >
            The first chapter of the Bhagavad Gita - <br /> "Arjuna Vishada
            Yoga"
          </h1>

          <div className="pt-4 text-center">
            <img loading="lazy" src={rect22} alt="Dr. Archika Didi" width={"70%"} />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg my-3 d-sm-5 rounded-0 fs-4 lato"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{
              backgroundColor: "#ff9933",
              color: "white",
              width: "200px",
            }}
          >
            Buy
          </button>
        </div>
        <p className="lh-lg w-75 text-center mx-auto py-4 lato">
          Originally, brahmana, kshatriya, vaishya, and shudra were not seen as
          sects of people who are born in certain families as it is understood
          today, but as descriptions of qualifications. A shudra was someone who
          did menial jobs; all he knew was his body. A vaishya was someone who
          was using his body, and to some extent, his mind. He was largely free
          of emotion, except for his own family. His body and his calculations
          ruled his nature. A kshatriya was passionate to the point of involving
          his life energies. He was more in his heart; otherwise he could not
          rule or lay down his life for what he believed in..
        </p>
      </div>

      {/* <OrderModal /> */}
    </>
  );
};

export default Cart2;
