import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import Moment from "react-moment";

import MetaData from "../component/layout/MetaData";
import crownimg from "../../src/images/crown.png";

import { getEventList } from "../Allslices/EventConfigSlice";
import { useSelector, useDispatch } from "react-redux";
function AnnualEvents() {
  const [displayedEvents, setDisplayedEvents] = useState(3);
  const eventsPerPage = 3;

  const loadMoreEvents = () => {
    setDisplayedEvents((prev) => prev + eventsPerPage);
  };

  const { eventType } = useParams();

  const { EventAllList } = useSelector((state) => state.Event_Config);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEventList(eventType));
  }, []);
  const Navigate = useNavigate();
  const singleevent = (slug) => {
    Navigate(`/events/eventdetails/${slug}`);
  };
  const { slug } = useParams();

  const annualsingleEvents = (slug) => {
    Navigate(`/eventblog/${slug}`);
  };

  let sliderRef1 = useRef();

  let sliderRef2 = useRef();


  const numCards = EventAllList?.alleventsFinal.filter((item) => !item.current)?.length || 0;
  // Determine the minimum number of slides to show
  const minSlidesToShow = Math.min(4, numCards);
  // Update slidesToShow

  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow, // Set slidesToShow to the minimum number of slides
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  settings2.slidesToShow = minSlidesToShow;
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col";
  }
  const settings1 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };
  const maxLength = 100;
  const maxLength2 = 300;
  const titlemaxLength = 19;

  return (
    <>
      <MetaData title={EventAllList?.config?.heading} />
      <div
        className="blogimg evenetbgb d-flex align-items-center"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(75, 100, 100, 1), rgba(75, 100, 100, 0.44)), url('${EventAllList?.config?.background_image}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 eventsres">
              <h1
                className="text-white d-flex align-items-end justify-content-center text-align-center text-center"
                style={{
                  fontSize: "4rem",
                  fontFamily: "Playfair Display",
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: 400,
                  // paddingTop: "250px",
                }}
              >
                {EventAllList?.config?.heading}
              </h1>
            </div>
          </div>
        </div>
      </div>


      {eventType && eventType === "current" && Array.isArray(EventAllList?.alleventsFinal) && EventAllList?.alleventsFinal.filter(item => item?.eventType === "Anual").length > 0 && (
        <section className="eventsres">
          <h1 className="text-center pt-5"

            style={{
              fontFamily: "Playfair Display",
              // fontSize: "3rem",
              color: "rgba(0, 0, 0, 1)",
              fontWeight: 700,
            }}
          >
            {eventType ? (eventType === "current" ? "Annual" : "Latest Upcoming") : ""}

          </h1>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center">
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.alleventsFinal) &&
                    EventAllList?.alleventsFinal
                      .filter((item) => item?.eventType === "Anual")
                      .map((element, innerIndex) =>
                        <div
                          className="col-md-6 col-sm-12 col-lg-4"
                          style={{ cursor: "pointer" }}

                          onClick={() => singleevent(element.slug)}
                        >
                          <div
                            className="card item-card text-dark mx-2   "
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              src={element?.thumbnail_image}
                              alt="Dr. Archika Didi"
                              style={{ borderRadius: "20px", aspectRatio: "3/2" }}
                            />
                            <div className="container">
                              <h4
                                className="mt-1 fs-4 text-capitalize"
                                style={{ fontFamily: "Playfair Display", color: "black" }}
                              >
                                {element?.title?.length > titlemaxLength
                                  ? `${element?.title.substring(0, titlemaxLength)}...`
                                  : element?.title}
                              </h4>
                              <h5
                                className="text-capitalize"
                                style={{
                                  fontSize: "18px",
                                  color: "rgba(0, 0, 0, 1)",
                                  fontFamily: "Lato",
                                  fontWeight: 600,
                                }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule?.location}
                              </h5>
                              <p
                                className="card-text lato text-capitalize"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.shortdescription?.length > maxLength
                                      ? `${element?.shortdescription.substring(0, maxLength)}...`
                                      : element?.shortdescription,
                                }}
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                              ></p>
                              <div></div>
                              {element?.isPaid ? (
                            <img loading="lazy" 
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                            </div>
                          </div>
                         
                        </div>

                      )}

                </Slider>
              </div>
            </div>
          </div>
        </section>


      )}
      {eventType && eventType === "current" && Array.isArray(EventAllList?.alleventsFinal) && EventAllList?.alleventsFinal.filter(item => item?.eventType === "Monthly").length > 0 && (
        <section className="eventsres">
          <h1 className="text-center pt-5"

            style={{
              fontFamily: "Playfair Display",
              fontSize: "3rem",
              color: "rgba(0, 0, 0, 1)",
              fontWeight: 700,
            }}
          >
            {eventType ? (eventType === "current" ? "Monthly" : "Latest Upcoming") : ""}

          </h1>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center">
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.alleventsFinal) &&
                    EventAllList?.alleventsFinal
                      .filter((item) => item?.eventType === "Monthly")
                      .map((element, innerIndex) =>
                        <div
                          className="col-md-6 col-sm-12 col-lg-4"
                          style={{ cursor: "pointer" }}

                          onClick={() => singleevent(element.slug)}
                        >
                          <div
                            className="card item-card text-dark mx-2  "
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              src={element?.thumbnail_image}
                              alt="Dr. Archika Didi"
                              style={{ borderRadius: "20px", aspectRatio: "3/2" }}
                            />
                            <div className="container">
                              <h4
                                className="mt-1 fs-4 text-capitalize"
                                style={{ fontFamily: "Playfair Display", color: "black" }}
                              >
                                {element?.title?.length > titlemaxLength
                                  ? `${element?.title.substring(0, titlemaxLength)}...`
                                  : element?.title}
                              </h4>
                              <h5
                                className="text-capitalize"
                                style={{
                                  fontSize: "18px",
                                  color: "rgba(0, 0, 0, 1)",
                                  fontFamily: "Lato",
                                  fontWeight: 600,
                                }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule?.location}
                              </h5>
                              <p
                                className="card-text lato text-capitalize"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.shortdescription?.length > maxLength
                                      ? `${element?.shortdescription.substring(0, maxLength)}...`
                                      : element?.shortdescription,
                                }}
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                              ></p>
                              <div></div>
                              {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                            </div>
                          </div>
                        </div>

                      )}

                </Slider>
              </div>
            </div>
          </div>
        </section>


      )}
      {eventType && eventType === "current" && Array.isArray(EventAllList?.alleventsFinal) && EventAllList?.alleventsFinal.filter(item => item?.eventType === "Special").length > 0 && (
        <section className="eventsres">
          <h1 className="text-center pt-5"

            style={{
              fontFamily: "Playfair Display",
              fontSize: "3rem",
              color: "rgba(0, 0, 0, 1)",
              fontWeight: 700,
            }}
          >
            {eventType ? (eventType === "current" ? "Special" : "Latest Upcoming") : ""}

          </h1>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center">
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.alleventsFinal) &&
                    EventAllList?.alleventsFinal
                      .filter((item) => item?.eventType === "Special")
                      .map((element, innerIndex) =>
                        <div
                          className="col-md-6 col-sm-12 col-lg-4"
                          style={{ cursor: "pointer" }}

                          onClick={() => singleevent(element.slug)}
                        >
                          <div
                            className="card item-card text-dark mx-2 "
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              src={element?.thumbnail_image}
                              alt="Dr. Archika Didi"
                              style={{ borderRadius: "20px", aspectRatio: "3/2" }}
                            />
                            <div className="container">
                              <h4
                                className="mt-1 fs-4 text-capitalize"
                                style={{ fontFamily: "Playfair Display", color: "black" }}
                              >
                                {element?.title?.length > titlemaxLength
                                  ? `${element?.title.substring(0, titlemaxLength)}...`
                                  : element?.title}
                              </h4>
                              <h5
                                className="text-capitalize"
                                style={{
                                  fontSize: "18px",
                                  color: "rgba(0, 0, 0, 1)",
                                  fontFamily: "Lato",
                                  fontWeight: 600,
                                }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule?.location}
                              </h5>
                              <p
                                className="card-text lato text-capitalize"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.shortdescription?.length > maxLength
                                      ? `${element?.shortdescription.substring(0, maxLength)}...`
                                      : element?.shortdescription,
                                }}
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                              ></p>
                              <div></div>
                              {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                            </div>
                          </div>
                        </div>

                      )}

                </Slider>
              </div>
            </div>
          </div>
        </section>


      )}
      {eventType && eventType === "current" && Array.isArray(EventAllList?.alleventsFinal) && EventAllList?.alleventsFinal.filter(item => item?.eventType === "Other").length > 0 && (
        <section className="eventsres">
          <h1 className="text-center pt-5 "

            style={{
              fontFamily: "Playfair Display",
              fontSize: "3rem",
              color: "rgba(0, 0, 0, 1)",
              fontWeight: 700,
            }}
          >
            {eventType ? (eventType === "current" ? "Others" : "Latest Upcoming") : ""}

          </h1>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center">
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.alleventsFinal) &&
                    EventAllList?.alleventsFinal
                      .filter((item) => item?.eventType === "Others")
                      .map((element, innerIndex) =>
                        <div
                          className="col-md-6 col-sm-12 col-lg-4"
                          style={{ cursor: "pointer" }}

                          onClick={() => singleevent(element.slug)}
                        >
                          <div
                            className="card item-card text-dark mx-2 "
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              src={element?.thumbnail_image}
                              alt="Dr. Archika Didi"
                              style={{ borderRadius: "20px", aspectRatio: "3/2" }}
                            />
                            <div className="container">
                              <h4
                                className="mt-1 fs-4 text-capitalize"
                                style={{ fontFamily: "Playfair Display", color: "black" }}
                              >
                                {element?.title?.length > titlemaxLength
                                  ? `${element?.title.substring(0, titlemaxLength)}...`
                                  : element?.title}
                              </h4>
                              <h5
                                className="text-capitalize"
                                style={{
                                  fontSize: "18px",
                                  color: "rgba(0, 0, 0, 1)",
                                  fontFamily: "Lato",
                                  fontWeight: 600,
                                }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule?.location}
                              </h5>
                              <p
                                className="card-text lato text-capitalize"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.shortdescription?.length > maxLength
                                      ? `${element?.shortdescription.substring(0, maxLength)}...`
                                      : element?.shortdescription,
                                }}
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                              ></p>
                              <div></div>
                              {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "3rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                            </div>
                          </div>
                        </div>

                      )}

                </Slider>
              </div>
            </div>
          </div>
        </section>


      )}









      {EventAllList?.alleventsFinal.length > 0 ? (<section>
        <div className=" container beloved eventsres">
          <h1 className="text-center pt-5"

            style={{
              fontFamily: "Playfair Display",
              // fontSize: "3rem",
              color: "rgba(0, 0, 0, 1)",
              fontWeight: 700,
            }}
          >
            {eventType ? (eventType === "current" ? "" : "Latest Upcoming") : ""}
          </h1>
        </div>
      </section>) : ("")}

      {eventType ? (eventType === "current" ? "" : (
        <section>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center">
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.alleventsFinal) &&
                    EventAllList?.alleventsFinal
                      .filter((item) => !item.current)
                      .slice(0, 15)
                      .map((element, innerIndex) => (
                        <div
                          className="gridColumnClass"
                          style={{ cursor: "pointer" }}
                          key={innerIndex}
                          onClick={() => singleevent(element.slug)}
                        >
                          <div
                            className="card item-card text-dark  mx-2 "
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              src={element?.thumbnail_image}
                              // src={element?.profile}
                              alt="Dr. Archika Didi"

                              style={{ borderRadius: "20px", aspectRatio: "3/2" }}
                            />
                            <div className="container">
                              <h4
                                className="mt-1 fs-4 text-capitalize"
                                style={{ fontFamily: "playfair", color: "black" }}
                              >
                                {element?.title?.length > titlemaxLength
                                  ? `${element.title.substring(0, titlemaxLength)}...`
                                  : element?.title}
                              </h4>
                              <h5
                                className="text-capitalize"
                                style={{
                                  fontSize: "18px",
                                  color: "rgba(0, 0, 0, 1)",
                                  fontFamily: "Lato",
                                  fontWeight: 600,
                                }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule[0]?.location}
                              </h5>

                              <p
                                className="card-text lato text-capitalize"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.shortdescription?.length > maxLength
                                      ? `${element.shortdescription.substring(
                                        0,
                                        maxLength
                                      )}...`
                                      : element?.shortdescription,
                                }}
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                              ></p>
                              <div></div>
                              {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                            </div>
                          </div>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )) : ""}

      {eventType ? (eventType === "current" ? "" : (
        EventAllList?.alleventsFinal.length > 0 ? (
          <section>
            <div className="container-fluid eventsres" style={{ background: "linear-gradient(180deg, #E5F0FB 32%, rgba(229, 240, 251, 0) 100%)" }}>
              <div className="py-5">
                <h1 className="text-center" style={{ 
                  // fontSize: "3rem", 
                fontFamily: "Playfair Display", color: "rgba(0, 0, 0, 1)", fontWeight: "700" }}>
                  List of all Events
                </h1>
              </div>
              <div className="container">
                <div className="row">
                  {Array.isArray(EventAllList?.alleventsFinal) &&
                    EventAllList?.alleventsFinal
                      .slice(0, displayedEvents)
                      .map((element, innerIndex) => (
                        <div key={`${innerIndex}-${innerIndex}`} className="col-md-6 col-sm-12 col-lg-12 mb-2 pb-3">
                          <div className="card item-card text-dark" onClick={() => singleevent(element.slug)}>
                            <div className="d-flex">
                              <img loading="lazy" src={element?.thumbnail_image} alt="Dr. Archika Didi" height={200} className="my-4 mx-5 rounded-2 d-none d-sm-block" />
                              <div className="card-body">
                                <h2 className="text-capitalize cardtitle" style={{ fontFamily: "Playfair Display ",color: "rgba(0, 0, 0, 1)", fontWeight: 700 }}>{element?.title}</h2>
                                <h5 className="" style={{ color: "rgba(253, 110, 18, 1)", fontWeight: 700 }}>
                                  <span>
                                    <Moment className="fw-semibold" format="YYYY/MM/DD HH:mm " withTitle>{element?.eventsSchedule[0]?.start_Date}</Moment>
                                  </span>
                                  <span>-</span>
                                  <span>
                                    <Moment className="fw-semibold" format=" YYYY/MM/DD HH:mm " withTitle>{element?.eventsSchedule[0]?.end_Date}</Moment>
                                  </span>
                                </h5>
                                <h4 className="fs-5 text-capitalize" style={{ fontWeight: 400 }}>
                                  {element?.shortdescription?.length >  maxLength2? `${element.shortdescription.substring(0, maxLength2)}...` : element?.shortdescription}
                                </h4>
                                <p className="text-capitalize" style={{ fontWeight: 600 }}>
                                  <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}{element?.eventsSchedule[0]?.location}
                                </p>
                              </div>
                            </div>
                            {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                          </div>
                        </div>
                      ))}
                </div>
                <div className="mt-5 text-center">
                  {EventAllList?.alleventsFinal && EventAllList?.alleventsFinal.length > displayedEvents && (
                    <button className="btn shadowbuttons btn-lg text-light rounded cmn-t-shake py-3 px-5 rounded-pill"  onClick={loadMoreEvents}>LOAD MORE</button>
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : null)
      ) : null}

      {eventType ? (eventType === "current" ? "" : (
        EventAllList?.alleventsFinal.length > 0 ? (
          <section>
            <div className="container-fluid" style={{ marginTop: "100px" }}>
              <div className="d-flex align-items-end justify-content-center">
                {EventAllList?.allBlog && EventAllList?.allBlog.length > 0 ? (
                  <h1
                    className="text-center"
                    style={{ fontFamily: "myfont", fontSize: "3rem" }}
                  >
                    Articles
                  </h1>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        ) : null
      )) : ""}



      {eventType ? (eventType === "current" ? "" : (
        <section>
          <div className="container-fluid beloved pt-5" 
          // style={{ backgroundColor: "#F9F9F9" }}
          >
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center ">
                <Slider
                  ref={(slider) => {
                    sliderRef1 = slider;
                  }}
                  {...settings1}
                >
                  {Array.isArray(EventAllList?.allBlog) && EventAllList?.allBlog.map((element, innerIndex) => (
                    <div
                      key={`${innerIndex}-${innerIndex}`}
                      className="col-md-6 col-sm-12 col-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => annualsingleEvents(element?.slug)}
                    >
                      <div className="card item-card text-dark mx-2" style={{ borderRadius: "20px" }}>
                        <img loading="lazy" src={element?.thumbnail_image} alt="Dr. Archika Didi" style={{ borderRadius: "20px", aspectRatio: "3/2" }} />
                        <div className="container">
                          <h4 className="lato pt-3" style={{ fontSize: "16px" }}>
                            <Moment className="fw-semibold" format="D MMM YYYY" withTitle>
                              {element?.createdAt}
                            </Moment>
                          </h4>
                          <h4 className="mt-1 fs-4 text-capitalize" style={{ fontFamily: "playfair", color: "black" }}>
                            {element?.title?.length > maxLength ? `${element.title.substring(0, maxLength)}...` : element?.title}
                          </h4>
                          <p
                            className="card-text lato text-capitalize"
                            dangerouslySetInnerHTML={{
                              __html: element?.shortdescription?.length > maxLength
                                ? `${element.shortdescription.substring(0, maxLength)}...`
                                : element?.shortdescription,
                            }}
                            style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                          />
                          <div></div>
                          
                        </div>
                      </div>
                      
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )) : ""}

    </>
  );
}

export default AnnualEvents;
