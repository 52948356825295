import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import Moment from "react-moment";
import Moment from "react-moment";

// import img2 from "../images/image 2.png";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
// import playbtn from "../images/playy-button (1).png";
import { useSelector, useDispatch } from "react-redux";
import { getSingleWellness } from "../Allslices/wellnessSlice";
import WellnessSonglist from "./WellnessSonglist";

import {
  pauseTrack,
  playTrack,
  setShowMusicBar,
  setTrackList,
} from "../Allslices/playerSlice";
import OrderModal from "../Payments/OrderModal";

import {
  bookmarkWellness,
  updateWellnessBookmarks,
} from "../component/auth/authSlice";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../appUtils/appToast";
import nodata from "../images/No-Data.png";
import { getmyOrder } from "../Allslices/orderSlice";
import MetaData from "../component/layout/MetaData";
import useGeolocation from "../component/shared/UserGeolocation";

const WellnessSinglePage = () => {
  const dispatch = useDispatch();
  const [buynow, setbuynow] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const default_image = location?.state?.default;
  const url = window.location.href;
  const { slug } = useParams();
  const { wellness } = useSelector((state) => state.allwellness);

  const { orderAll } = useSelector((state) => state?.order);
  const [checkBuyStatus, setCheckBuyStatus] = useState();

  const sellingPriceINR = wellness?.selling_price_rupee;
  const mrpPriceINR = wellness?.mrp_price_rupee;
  const sellingPriceUSD = wellness?.selling_price_usd;
  const mrpPriceUSD = wellness?.mrp_price_usd;

  const userGeolocationCode = useGeolocation();

  const isIndianUser = userGeolocationCode === "IN";
  let mrpPrice =
    isIndianUser || !userGeolocationCode ? mrpPriceINR : mrpPriceUSD;
  let sellingPrice =
    isIndianUser || !userGeolocationCode ? sellingPriceINR : sellingPriceUSD;

  const renderPrice = () => {
    const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";

    if (sellingPrice === 0) {
      return `Get Now (FREE)`;
    } else if (sellingPrice === mrpPrice) {
      return `Get Now (${currencySymbol}${sellingPrice})`;
    } else if (sellingPrice < mrpPrice) {
      return (
        <>
          Buy Now{" "}
          <span style={{ textDecoration: "line-through", color: "white" }}>
            {currencySymbol}
            {mrpPrice}
          </span>{" "}
          ({currencySymbol}
          {sellingPrice}){" "}
        </>
      );
    } else {
      return `Buy Now (${currencySymbol}${sellingPrice})`;
    }
  };

  const postid = wellness?._id;
  const { currentTrack, isPlaying, trackList } = useSelector(
    (state) => state.player
  );
  const { isLoading, user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getSingleWellness(slug));
  }, []);

  const handlenavigateTosinglewellnessvideo = (slug) => {
    if (wellness?.isPaid) {
      if (checkBuyStatus) {
        navigate(`/wellness/video/${slug}`);
      } else {
        SHOW_INFO_NOTIFICATION("Please Buy To View More");
      }
    } else {
      navigate(`/wellness/video/${slug}`);
    }
  };

  const handlenavigateTosinglewellnessarticle = (slug) => {
    if (wellness?.isPaid) {
      if (checkBuyStatus) {
        navigate(`/wellness/article/${slug}`);
      } else {
        SHOW_INFO_NOTIFICATION("Please Buy Wellness To See Full Details");
      }
    } else {
      navigate(`/wellness/article/${slug}`);
    }
  };

  const handleBookmark = async () => {
    const isBookmarked = user?.wellnessBookmarks.includes(wellness.slug);

    try {
      dispatch(bookmarkWellness(wellness._id)).then((result) => {
        if (result?.error) {
          SHOW_ERROR_NOTIFICATION(result.payload);
          return;
        } else {
          SHOW_SUCCESS_NOTIFICATION("Your Bookmarks have been updated");
        }
      });
    } catch (error) {
      SHOW_ERROR_NOTIFICATION(error.message);
    }
  };

  let splitUrl;

  if (wellness?.page_banner_image) {
    splitUrl = wellness?.page_banner_image.split(
      "http://api.drarchikadidi.com/"
    );
    // splitUrl = wellness?.page_banner_image.split('http://localhost:8080/');
  }
  let bannerImage;

  // const shareMessage = `${wellness?.meta_title}\n\n${wellness?.meta_description}\n\n#${wellness?.meta_tag}\n\n`;
  const shareHashtag = `#${wellness?.meta_title}\n#${wellness?.meta_tag}#${
    wellness?.title
  }\n#${wellness?.title}\n${
    Array.isArray(wellness?.tags) &&
    wellness?.tags.length > 0 &&
    wellness?.tags?.map((item) => `#${item?.name}`)
  }#${wellness?.slug}\n#DrArchikaDidi`;

  const shareHashtag_twitter = [
    `#${wellness?.meta_title}\n#${wellness?.meta_tag}#${wellness?.title}\n#${
      wellness?.title
    }\n${
      Array.isArray(wellness?.tags) &&
      wellness?.tags.length > 0 &&
      wellness?.tags?.map((item) => `#${item?.name}`)
    }#${wellness?.slug}\n#DrArchikaDidi`,
  ];

  const shareMessage = `${wellness?.meta_title}\n\n${wellness?.meta_description}`;

  const shareHashtag_fb = `#${wellness?.meta_tag || "DrArchikaDidi"}`;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? default_image
        : wellness?.page_banner_image;
  }

  const orderType = "wellness";

  const handlebuynow = () => {
    setbuynow(true);
  };

  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  useEffect(() => {
    // Fetch the orders every time the component mounts or after purchase
    dispatch(getmyOrder());
  }, [dispatch]);
  
  useEffect(() => {
    if (orderAll && wellness?._id) {
      const purchaseStatus = orderAll.filter(
        (item) => item?.orderItems?.product === wellness?._id
      );
      const isPurchased = purchaseStatus[0]?.orderStatus === "Confirm";
      setCheckBuyStatus(isPurchased);
    }
  }, [orderAll, wellness]);
  

  useEffect(() => {
    setIsBookmarked(user?.wellnessBookmarks.includes(postid));
  }, [user?.wellnessBookmarks, isBookmarked]);
  const maxLength = 100;
  const titlemaxLength = 25;

  let songList = [];

  for (let i = 0; i < (wellness?.combinedList ?? []).length; i++) {
    const item = wellness?.combinedList[i];
    if (item?.type == "audio") {
      songList.push(item);
    }
  }

  const onSongPlay = (song) => {
    if (wellness?.isPaid) {
      if (checkBuyStatus) {
        // const index = wellness?.songList.findIndex((s) => s._id == song._id);
        const index = songList.findIndex((s) => s._id == song._id);
        dispatch(setTrackList({ list: songList, index }));
        dispatch(playTrack(song));
      } else {
        SHOW_INFO_NOTIFICATION("Please Buy Wellness To See Full Details");
      }
    } else if (!wellness?.isPaid) {
      const index = songList.findIndex((s) => s._id == song._id);
      dispatch(setTrackList({ list: songList, index }));
      dispatch(playTrack(song));
    }
  };

  const onSongPause = (song) => {
    const index = songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: songList, index }));
    dispatch(pauseTrack(song));
    dispatch(setShowMusicBar(false));
  };

  return (
    <>
      <MetaData
        title={wellness?.meta_title}
        description={wellness?.meta_description}
        tags={wellness?.meta_tag}
      />
      {/* banner section */}
      <div
        className=" blogimg mb-2 meditation"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container blogsub">
          <div className="row text-center p-5">
            <div className="col-lg-12 col-sm-12">
              <h1 className=" text-dark" style={{ fontFamily: "myFont" }}>
                {wellness?.title}
              </h1>
              <p
                className="pt-1 text-dark fw-semibold mt-4"
                style={{ fontFamily: "lato" }}
                dangerouslySetInnerHTML={{ __html: wellness?.description }}
              ></p>
            </div>
            <div className="pt-3 mt-3 d-flex justify-content-center">
              <img
                src={wellness?.thumbnail_image}
                alt="Dr. Archika Didi"
                className=""
                style={{
                  borderRadius: "10px",
                  boxShadow: "rgb(0, 0, 0) 1px 1px 17px 2px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* banner section end */}
      {/* text section all */}
      <section style={{ overflow: "hidden" }}>
        <div className="container ">
          <div className="textrt">
            <div className="row icotext text-center">
              <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center ">
                <h1 className="text-center fs-1 fw-bold my-3 wellallepi">
                  <span>All Episodes </span>
                </h1>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center ">
                {/* <a href="#" className="p-1"> */}
                <a className="p-1">
                  <FacebookShareButton
                    // url={"https://archikadidi.com"}
                    url={url}
                    hashtag={shareHashtag_fb}
                    quote={shareMessage}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                </a>

                <a className="p-1">
                  <WhatsappShareButton
                    title={shareMessage}
                    url={url}
                    hashtag={shareHashtag}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                </a>
                <a className="p-1">
                  <TwitterShareButton
                    title={shareMessage}
                    hashtag={shareHashtag_twitter}
                    url={url}
                  >
                    <XIcon size={40} round={true} />
                  </TwitterShareButton>
                </a>
                <a className="p-1">
                  <TelegramShareButton title={shareMessage} url={url}>
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>
                </a>
                {/* <a href="#" className="p-1">
                  <a href="#" className="p-1">
                    {user?.wellnessBookmarks.includes(postid) ? (
                      <i
                        onClick={handleBookmark}
                        className="fa-solid fa-bookmark"
                        style={{ color: "#000", fontSize: "40px" }}
                      ></i>
                    ) : (
                      <i
                        onClick={handleBookmark}
                        className="fa-regular fa-bookmark"
                        style={{ color: "#000", fontSize: "35px" }}
                      ></i>
                    )}
                  </a>
                </a> */}
              </div>
            </div>
            <div className="contyu container pt-3 ps-5" />
            {/* <p
              dangerouslySetInnerHTML={{ __html: wellness?.content }}
              className="ms-2"
            ></p> */}
          </div>
        </div>
      </section>
      {/* text section all end */}

      <div className="pt-2 text-center" style={{ overflow: "hidden" }}>
        {(checkBuyStatus === false || checkBuyStatus === undefined) &&
        wellness?.isPaid === true ? (
          <button
            className="btn shadowbuttons btn-lg text-light rounded-pill cmn-t-shake mb-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            // style={{ backgroundColor: "#FF9933" }}
            onClick={handlebuynow}
          >
            {sellingPrice >= 0 ? renderPrice() : "GET NOW (FREE)"}
          </button>
        ) : (
          ""
        )}
      </div>
      {/* Wellness Full Description Start */}
      <section>
        <div className="container">
          <div className="textrt ">
            <div
              className="contyu mt-3 text-center"
              style={{ fontSize: "20px", padding: "15px 0 15px 0" }}
              dangerouslySetInnerHTML={{
                __html: wellness?.fulldescription,
              }}
            ></div>
          </div>
        </div>
      </section>
      {/* Wellness Full Description End */}
      {/* all episodes */}
      {wellness?.combinedList?.length > 0 ? (
        <div className="tacky">
          <div className="container">
            <div className="tackuty ">
              {Array.isArray(wellness?.combinedList) &&
                wellness?.combinedList?.length > 0 &&
                wellness?.combinedList.map((item, index) => (
                  <React.Fragment key={index || item.slug}>
                    {item.type === "audio" && item.status === true && (
                      <WellnessSonglist
                        key={index}
                        song={item}
                        handlePlay={onSongPlay}
                        handlePause={onSongPause}
                        checkBuyStatus={checkBuyStatus}
                        isPaid={wellness?.isPaid}
                        albumid={wellness?.slug}
                      />
                    )}

                    {item.type === "video" && (
                      <div key={index} className="apixoss py-4 mt-2">
                        {checkBuyStatus !== true &&
                          wellness?.isPaid === true &&
                          item.isPaid === true && (
                            <i className="fas fa-lock fs-4 pt-2"></i>
                          )}
                        <i className="fa-solid fa-video fs-4 pt-2"></i>
                        <p className="pt-2 yera yeratitle fw-semibold">
                          {item?.title}
                        </p>
                        <p className="pt-2 yera fw-semibold">
                          <i className="fa-solid fa-video px-2 text-info"></i>{" "}
                          VIDEO
                        </p>
                        <a
                          className="btn vieww text-dark text-dark yera"
                          onClick={() =>
                            handlenavigateTosinglewellnessvideo(item?.slug)
                          }
                        >
                          View More <i className="fa-solid fa-angle-right"></i>
                        </a>
                      </div>
                    )}

                    {item.type === "article" && (
                      <div key={index} className="apixoss py-4 mt-2">
                        <i className="fa-solid fa-bars fs-4 pt-2"></i>
                        <p className="pt-2 yera yeratitle fw-semibold">
                          {item?.title}
                        </p>
                        <p className="pt-2 yera fw-semibold">
                          <i className="fa-solid fa-book-open-reader px-2 text-info"></i>{" "}
                          ARTICLE
                        </p>
                        <a
                          className="btn vieww text-dark text-dark pt-2 yera"
                          onClick={() =>
                            handlenavigateTosinglewellnessarticle(item?.slug)
                          }
                        >
                          View More <i className="fa-solid fa-angle-right"></i>
                        </a>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      ) : null}

      <OrderModal
        orderType={orderType}
        product={buynow ? wellness : ""}
        navigate={true}
        isExpired={false}
      />
    </>
  );
};

export default WellnessSinglePage;
