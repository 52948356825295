import { ReactComponent as Loader } from '../../images/loader.svg';
import './AppButton.css';

const AppButton = ({ onSubmit, text, loading = false, disabled }) => {
    return (
        <button className="app-btn px-2 py-1" onClick={onSubmit} disabled={disabled || loading}>
            {!loading ? text : <Loader className="spinner" />}
        </button>
    )
}

export default AppButton