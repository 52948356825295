import React, { useCallback, useEffect, useState } from "react";
import Background1 from "../../src/images/Rectangle38.png";
import "../component/layout/style.css";
import "../component/auth/auth.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderPage from "./LoaderPage";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getuserintrest,
  loadUser,
  updateUserProfile,
} from "../component/auth/authSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import Quill styles

import Moment from "react-moment";
import moment from "moment";
import AppCarousel from "../component/shared/AppCarousel";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { getmyOrder } from "../Allslices/orderSlice";
import { getAllOccupationList } from "../Allslices/occupationSlice";
import {
  getuserAnswers,
  getAnswerResetPage,
  getuserQuestionsandThoughts,
} from "../component/auth/authSlice";

import { getcoinsValue } from "../Allslices/coinsSlice";
import { fetchPostResetPage, fetchPosts } from "../Allslices/communitySlice";
import PostItem from "./post/PostItem";
import MetaData from "../component/layout/MetaData";
import Select from "react-select";
import {
  getCountryList,
  getStatelist,
  getCitylist,
} from "../Allslices/countrycitystateSlice";
import {
  SHOW_INFO_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../appUtils/appToast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Modal, ModalBody } from "react-bootstrap";
function Profile() {
  const [myLoader, setMyLoader] = useState(false);
  const [music, setMusic] = useState(false);
  const [song, setsong] = useState(false);
  const [overview, setOverview] = useState(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestions] = useState(false);
  const [showOrder, setShowOrder] = useState([]);
  const [course, setCourse] = useState(false);
  const [answers, setAnswers] = useState(false);
  const [thought, setThoughts] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [bhagwat, setBhagwat] = useState(false);
  const [wellness, setWellness] = useState(false);
  const [events, setEvents] = useState(false);
  const [podcast, setPodcast] = useState(false);
  const { countrylist, statelist, citylist } = useSelector(
    (state) => state?.countrystatecity
  );
  const { occupation } = useSelector((state) => state.occupationList);
  const [page, setPage] = useState(1);
  const [thoughtPage, setThoughtPage] = useState(1);
  const { orderAll } = useSelector((state) => state?.order);

  const orderWithSongParentId = orderAll.find(
    (order) => order.orderItems?.songParentId !== undefined
  );

  const singesongParentId = orderWithSongParentId?.orderItems?.songParentId;
  const {
    user,
    userQuestionandThoughtlist,
    userAnswer,
    currentPage,
    totalPages,
    isLoading,
    intrest,
    isProfileUpdated,
  } = useSelector((state) => {
    return state.auth;
  });

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const slug = searchParams.get("slug");

  const handleOrder = (orderType) => {
    let myOlderList;
    let musicOlderList;
    let songOlderList;

    if (orderType == "music" || orderType == "song") {
      musicOlderList = orderAll?.filter((item) => item?.orderType === "music");
      songOlderList = orderAll?.filter((item) => item?.orderType === "song");
      setShowOrder([...musicOlderList, ...songOlderList]);
    } else {
      myOlderList = orderAll?.filter((item) => item?.orderType === orderType);
      setShowOrder(myOlderList);
    }
  };

  const {
    fetchPostState: {
      loading: fetchPostloading,
      data: fetchPostData,
      page: postPage,
      hideLoadMore,
    },
  } = useSelector((state) => state.community);

  const handleOnClicknavigation = (params) => {
    setMusic(false);
    setsong(false);
    setCourse(false);
    setPodcast(false);
    setBhagwat(false);
    setWellness(false);
    setEvents(false);
    setOverview(false);
    setQuestions(false);
    setAnswers(false);
    setThoughts(false);
    setActiveTab("");

    switch (params) {
      case "music":
        setMusic(true);
        break;
      case "song":
        setsong(true);
        break;
      case "podcast":
        setPodcast(true);
        break;
      case "bhagwat":
        setBhagwat(true);
        break;
      case "wellness":
        setWellness(true);
        break;
      case "events":
        setEvents(true);
        break;
      // // // case "donation":
      // //   setDonation(true);
      //   break;
      case "overview":
        setOverview(true);
        break;
      case "question":
        setQuestions(true);
        break;
      case "answers":
        setAnswers(true);
        break;
      case "thought":
        setThoughts(true);
        break;
      case "course":
        setCourse(true);
    }
  };

  const handleNavigation = (item) => {
    if (item?.orderType === "course") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));
      if (courseExpired != "Expired") {
        navigate(`/course/${item?.orderItems?.slug}`, {
          state: {
            orderid: `${item?._id}`,
            productId: `${item?.orderItems?.product}`,
          },
        });
      } else {
        navigate(`/courses/${item?.orderItems?.slug}`);
      }
    }
    if (item?.orderType === "music") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));
      if (courseExpired != "Expired") {
        navigate(`/music/${item?.orderItems?.slug}`, {
          state: { key: `${item?._id}` },
        });
      } else {
        navigate(`/music/${item?.orderItems?.slug}`);
      }
    }

    if (item?.orderType === "song") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));

      if (courseExpired != "Expired") {
        navigate(
          `/music/${item?.orderItems?.songParentId}/${item?.orderItems?.slug}`,
          {
            state: { key: `${item?._id}` },
          }
        );
      } else {
        window.location.href = `/music/${item?.orderItems?.songParentId}/${item?.orderItems?.slug}`;
      }
    }

    if (item?.orderType === "events") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));

      if (courseExpired != "Expired") {
        navigate(
          `/events/eventdetails/${item?.orderItems?.eventParent}/${item?.orderItems?.slug}`,
          {
            state: { key: `${item?._id}` },
          }
        );
      } else {
        navigate(
          `/events/eventdetails/${item?.orderItems?.eventParent}/${item?.orderItems?.slug}`
        );
      }
    }
    if (item?.orderType === "wellness") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));
      if (courseExpired != "Expired") {
        navigate(`/wellness/${item?.orderItems?.slug}`, {
          state: { key: `${item?._id}` },
        });
      } else {
        navigate(`/wellness/${item?.orderItems?.slug}`);
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setMusic(false);
    setsong(false);
    setOverview(false);
    setQuestions(false);
    setAnswers(false);
    setThoughts(false);
  };

  const [formdata, setFormData] = useState({
    username: "",
    image: null,
    useroccupation: "",
    userintrest: "",
    userbio: "",
    whatsapp: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    dob: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    countryCode: "",
  });
  const [whatsapp, setWhatsapNo] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const handlewhatsappChange = (value, data) => {
    setCountryCode(`+${data.dialCode}`);
    const number = value.slice(data.dialCode.length);
    setWhatsapNo(number);
    setFormData({
      ...formdata,
      whatsapp: value,
    });
  };
  useEffect(() => {
    if (user) {
      let userOccupation;
      if (occupation && occupation.length > 0) {
        userOccupation = occupation?.find(
          (occ) => occ.name === user.occupation
        );
      }
      setFormData({
        username: user?.name,
        image: user?.image,
        useroccupation: userOccupation,
        userintrest: user?.interest?._id,
        userbio: user?.bio,
        whatsapp: user?.whatsapp,
        country: user?.country,
        state: user?.state,
        city: user?.city,
        pincode: user?.pincode,
        dob: user?.dob,
        addressLine1: user?.addressLine1,
        addressLine2: user?.addressLine2,
        addressLine3: user?.addressLine3,
      });
    }
    dispatch(getuserintrest());
    dispatch(getCountryList());
    let countryId = user?.country?.id;
    dispatch(getStatelist({ countryId }));
    let countryidd = user?.country?.id;
    let stateidd = user?.state?.id;
    dispatch(getCitylist({ countryidd, stateidd }));
  }, [user, dispatch, occupation]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append(name, file);
      setFormData({ ...formdata, [name]: file });
    } else if (name === "userintrest") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      const userintrest =
        selectedOptions.length === 0 ? formdata.userintrest : selectedOptions;
      setFormData({
        ...formdata,
        [name]: userintrest,
      });
    } else if (name === "useroccupation") {
      setFormData({
        ...formdata,
        [name]: value,
      });
    } else {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e;

    if (name === "country") {
      let countryId = value?.id;
      // Reset state and city when country is changed
      setFormData({
        ...formdata,
        country: value,
        state: null,
        city: null,
      });
      dispatch(getStatelist({ countryId }));
    } else if (name === "state") {
      let countryidd = value?.country_id;
      let stateidd = value?.id;
      // Reset city when state is changed
      setFormData({
        ...formdata,
        state: value,
        city: null,
      });
      dispatch(getCitylist({ countryidd, stateidd }));
    } else {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
    if (name == "useroccupation") {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedUserData = new FormData();
    updatedUserData.append("name", formdata.username);
    updatedUserData.append("occupation", formdata.useroccupation?.name);
    updatedUserData.append("intrest", formdata.userintrest);
    updatedUserData.append("bio", formdata.userbio);
    updatedUserData.append("image", formdata.image); // This is the critical part
    updatedUserData.append("whatsapp", formdata.whatsapp);
    updatedUserData.append("country", JSON.stringify(formdata.country));
    updatedUserData.append("state", JSON.stringify(formdata.state));
    updatedUserData.append("city", JSON.stringify(formdata.city));
    updatedUserData.append("pincode", formdata.pincode);
    updatedUserData.append("dob", formdata.dob);
    updatedUserData.append("addressLine1", formdata.addressLine1);
    updatedUserData.append("addressLine2", formdata.addressLine2);
    updatedUserData.append("addressLine3", formdata.addressLine3);
    updatedUserData.append("countryCode", formdata.countryCode);
    startLoader();
    dispatch(updateUserProfile(updatedUserData))
      .then((result) => {
        if (result.payload.success) {
          closeLoader();
          handleClose();
          SHOW_SUCCESS_NOTIFICATION("Successfully updated");
        }
      })
      .catch((error) => {
        closeLoader();
        handleClose();
        console.error("Error updating profile:", error);
        SHOW_ERROR_NOTIFICATION("Failed to update profile. Please try again.");
      });
  };

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(loadUser());
    } else {
      dispatch(loadUser());
    }
  }, [dispatch, isProfileUpdated]);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  useEffect(() => {
    dispatch(getmyOrder());
    dispatch(getcoinsValue());

    dispatch(fetchPostResetPage());

    dispatch(getAnswerResetPage());
    dispatch(getAllOccupationList());
  }, [dispatch]);

  const [hasMoreData, setHasMoreData] = useState(true);

  const userId = localStorage.getItem("userId");

  const callFetchPost = useCallback((type, page) => {
    dispatch(fetchPosts({ type, user: userId, page })).then((resultAction) => {
      if (resultAction?.error) {
        SHOW_ERROR_NOTIFICATION(resultAction.payload);
        return;
      }
    });
  }, []);

  function loadmoreThoughts(type) {
    callFetchPost(type, thoughtPage + 1);
    setThoughtPage(thoughtPage + 1);
  }

  useEffect(() => {
    if (key === null) {
      setOverview(true);
    } else {
      handleOnClicknavigation(key);
      callFetchPost(key);
      handleOrder(key);
    }
  }, [key]);
  const handleLoadMoreAnswers = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (currentPage != totalPages) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [currentPage, totalPages]);
  useEffect(() => {
    if (user?._id)
      dispatch(getuserAnswers({ userId: user._id, page: page })).then(
        (resultAction) => {
          if (resultAction?.error) {
            SHOW_ERROR_NOTIFICATION(resultAction.payload);
            return;
          }
          if (resultAction.payload?.length < 10) {
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        }
      );
  }, [page]);


  const calculateDaysLeft = (item) => {
    const createdAt = moment(item.createdAt);

    const expiryDate = moment(createdAt).add(
      item?.orderItems?.monthValidity || 0,
      "months"
    );

    const now = moment();
    const daysLeft = expiryDate.diff(now, "days");
    return daysLeft;
  };

  const formatDaysLeft = (days) => {
    if (days > 1) {
      return `${days} days Left`;
    } else if (days === 1) {
      return `1 day left`;
    } else {
      return `Expired`;
    }
  };

  const formatFutureDate = (daysLeft, item) => {
    if (daysLeft > 0) {
      const futureDate = moment(item.createdAt)
        .add(item?.orderItems?.monthValidity || 0, "months")
        .format("D MMM YYYY");
      return `${futureDate} ,`;
    } else {
      return "";
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };
  const startLoader = () => {
    setMyLoader(true);
  };
  const closeLoader = () => {
    setMyLoader(false);
  };

  return (
    <>
      <MetaData title="Profile" />
      {user ? (
        <>
          <div
            className={`profile_box  `}
            style={{
              backgroundImage: `url(${Background1})`,
              backgroundSize: "cover",
              top: "0rem",
            }}
          >
            <div
              className="container"
              style={{
                paddingTop: "10rem",
              }}
            >
              <div className="row mb-2 " style={{ justifyContent: "center" }}>
                <div className="col-lg-11 col-12 mb-5">
                  <div className="text-center">
                    <h1>
                      <span
                        style={{
                          fontSize: "60px",
                          position: "relative",
                          bottom: "1.8rem",
                        }}
                      >
                        Dashboard
                      </span>
                    </h1>
                  </div>
                  <div className={`profile_container  `}>
                    <div className="profileTopContainer d-flex flex-wrap p-3 ">
                      <div className="m-1 rounded-circle profile_user_img">
                        <img
                          src={user?.image ?? ""}
                          alt="Dr. Archika Didi"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "default_usericon.jpg";
                            currentTarget.style.width = "100px";
                          }}
                        />
                      </div>
                      <div className="medals_container  ">
                        <h5 className="text-muted mx-3">
                          {user?.name}
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#0c5de9", paddingLeft: "0.5rem" }}
                            onClick={handleShow}
                          ></i>
                        </h5>

                        {/* <!-- Modal --> */}

                        <Modal
                          show={showModal}
                          onHide={handleClose}
                        >
                          <Modal.Header>
                            <h1 className="fs-5" id="exampleModalLabel">
                              Edit Profile
                            </h1>
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={handleClose}
                            ></button>
                          </Modal.Header>
                          <Modal.Body>
                            <form
                              encType="multipart/form-data"
                              onSubmit={handleSubmit}
                            >
                              <div className="modal-body">
                                <div className="mb-3">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Name
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type="text"
                                      className="form-control "
                                      id="username"
                                      name="username"
                                      value={formdata.username}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Profile Pic
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="file"
                                      name="image"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>

                               

                                <div className="mb-3">
                                  <label
                                    htmlFor="useroccupation"
                                    name="useroccupation"
                                    className="form-label mb-1"
                                  >
                                    Occupation :
                                  </label>
                                  <div className="position-relative">
                                    <Select
                                      options={occupation}
                                      getOptionLabel={(options) =>
                                        options["name"]
                                      }
                                      getOptionValue={(options) =>
                                        options["name"]
                                      }
                                      name="useroccupation"
                                      value={formdata.useroccupation}
                                      placeholder="Occupation"
                                      onChange={(e) =>
                                        handleSelectChange({
                                          value: e,
                                          name: "useroccupation",
                                        })
                                      }
                                    />
                                   
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <label
                                    htmlFor="userintrest"
                                    className="form-label"
                                  >
                                    Interest
                                  </label>
                                  <select
                                    id="userintrest"
                                    name="userintrest"
                                    className="form-select"
                                    value={formdata.userintrest}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" disabled>
                                      Select Interest
                                    </option>
                                    {intrest &&
                                      intrest.map((item) => (
                                        <option key={item._id} value={item._id}>
                                          {" "}
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="mb-3 whatsappp">
                                  <label
                                    htmlFor="whatsapp"
                                    className="form-label"
                                  >
                                    WhatsApp No.
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    
                                    <PhoneInput
                                      country={"in"}
                                      className="form-control ps-0 textgray"
                                      enableSearch
                                      required
                                      value={formdata?.whatsapp}
                                      type="number"
                                      name="whatsapp"
                                      id="form4"
                                      countryCodeEditable={false}
                                      placeholder="Enter WhatsApp No."
                                      onChange={handlewhatsappChange}
                                    />
                                    
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="dob" className="form-label">
                                    DOB
                                  </label>

                                  <input
                                    type="date"
                                    className="form-control"
                                    id="dob"
                                    name="dob"
                                    value={formdata.dob}
                                    onChange={handleInputChange}
                                  />
                                </div>

                                <label
                                  htmlFor="address"
                                  className="mt-2 form-label"
                                >
                                  Address
                                </label>
                                <div className="mb-3 d-flex flex-column gap-2">
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="addressLine1"
                                      name="addressLine1"
                                      value={formdata.addressLine1}
                                      placeholder="Address Line 1"
                                      onChange={handleInputChange}
                                    />
                                    
                                  </div>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="addressLine2"
                                      name="addressLine2"
                                      placeholder="Address Line 2"
                                      value={formdata.addressLine2}
                                      onChange={handleInputChange}
                                    />
                                    
                                  </div>

                                  <div style={{ position: "relative" }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="addressLine3"
                                      name="addressLine3"
                                      placeholder="Address Line 3"
                                      value={formdata.addressLine3}
                                      onChange={handleInputChange}
                                    />
                                   
                                  </div>
                                </div>
                                <div className="mb-3">
                                 

                                  <Select
                                    options={countrylist}
                                    getOptionLabel={(options) => {
                                      return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                      return options["name"];
                                    }}
                                    value={formdata.country}
                                    name="country"
                                    placeholder="Country"
                                    
                                    onChange={(e) =>
                                      handleSelectChange({
                                        value: e,
                                        name: "country",
                                      })
                                    }
                                  />
                                </div>
                                <div className="mb-3">


                                  <Select
                                    options={statelist}
                                    getOptionLabel={(options) => {
                                      return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                      return options["name"];
                                    }}
                                    value={formdata.state}
                                    name="state"
                                    placeholder="State"
                                    onChange={(e) =>
                                      handleSelectChange({
                                        value: e,
                                        name: "state",
                                      })
                                    }
                                  />
                                </div>
                                <div className="mb-3">
                                  

                                  <Select
                                    options={citylist}
                                    getOptionLabel={(options) => {
                                      return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                      return options["name"];
                                    }}
                                    value={formdata.city}
                                    name="city"
                                    placeholder="City"
                                    onChange={(e) =>
                                      handleSelectChange({
                                        value: e,
                                        name: "city",
                                      })
                                    }
                                  />
                                </div>

                                <div className="mb-3">
                                  <label
                                    htmlFor="pincode"
                                    className="form-label"
                                  >
                                    Pin Code
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="pincode"
                                      name="pincode"
                                      value={formdata.pincode}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="userbio"
                                    className="form-label"
                                  >
                                    Bio
                                  </label>
                                  <ReactQuill
                                    value={formdata.userbio}
                                    onChange={(value) =>
                                      setFormData({
                                        ...formdata,
                                        userbio: value,
                                      })
                                    }
                                    modules={modules}
                                    formats={formats}
                                  />
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="submit"
                                    className="btn btn-primary "
                                    disabled={myLoader}
                                  >
                                    {myLoader ? (
                                      <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                        style={{
                                          top: "18%",
                                          left: "45%",
                                          transform: "translate(-50%, -50%)",
                                          animation:
                                            "rotateSpinner 1s linear infinite",
                                        }}
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Update"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </Modal.Body>
                        </Modal>

                        <a className="text-muted mx-3">{user?.email}</a>
                      </div>
                    </div>

                    <ul
                      className="profile_footer d-flex flex-wrap p-2"
                      style={{
                        listStyle: "none",
                        borderTop: "2px solid #d7d0d0",
                        fontWeight: "600",
                      }}
                    >
                      <li
                        className={`px-2 ${overview ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("overview")}
                      >
                        <div style={{ cursor: "pointer" }}>Overview</div>
                      </li>
                      <li
                        className={`px-2 ${question ? "active-tab" : ""}`}
                        onClick={() => {
                          handleOnClicknavigation("question");
                          dispatch(fetchPostResetPage());
                          callFetchPost("question");
                        }}
                      >
                        <div id="myquestions" style={{ cursor: "pointer" }}>
                          My Question
                        </div>
                      </li>
                      <li
                        className={`px-2 ${answers ? "active-tab" : ""}`}
                        onClick={() => {
                          setPage(0);
                          handleOnClicknavigation("answers");
                          dispatch(getAnswerResetPage());
                          dispatch(
                            getuserAnswers({ userId: user?._id, page: 0 })
                          ).then((resultAction) => {
                            if (resultAction?.error) {
                              SHOW_ERROR_NOTIFICATION(resultAction.payload);
                              return;
                            }
                            if (resultAction.payload?.length < 10) {
                              setHasMoreData(false);
                            } else {
                              setHasMoreData(true);
                            }
                          });
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>My Answer</div>
                      </li>
                      <li
                        className={`px-2 ${thought ? "active-tab" : ""}`}
                        onClick={() => {
                          handleOnClicknavigation("thought");
                          
                          dispatch(fetchPostResetPage());
                          callFetchPost("thought");
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>My Thoughts</div>
                      </li>
                      <li
                        className={`px-2 ${course ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("course")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("course")}
                        >
                          {" "}
                          My Course
                        </div>
                      </li>
                      <li
                        className={`px-2 ${music ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("music")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("music")}
                        >
                          My Music
                        </div>
                      </li>

                      {/* <li
                        className="px-2 "
                        // onClick={() => handleTabClick("Welcome")}
                        onClick={() => handleTabClick("podcast")}
                      >
                        <div style={{ cursor: "pointer" }} onClick={()=>handleOrder("podcast")}>My Podcast</div>
                      </li> */}

                      {/* <li
                        className={`px-2 ${bhagwat ? "active-tab" : ""}`}
                        // onClick={() => handleOnClicknavigation("bhagwatgeeta")}
                        onClick={() => handleOnClicknavigation("bhagwat")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("bhagwat")}
                        >
                          My Bhagwat Geeta
                        </div>
                      </li>  */}

                      <li
                        className={`px-2 ${wellness ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("wellness")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("wellness")}
                        >
                          My Wellness
                        </div>
                      </li>

                      <li
                        className={`px-2 ${events ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("events")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("events")}
                        >
                          My Events
                        </div>
                      </li>
                    </ul>
                  </div>
                  {overview ? (
                    <div
                      className="profile_container container mt-4 p-3"
                      style={{ height: "10rem !important" }}
                    >
                      <div className="row">
                        <div className="col">
                          <h4>Info</h4>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Occupation:</h5>
                        </div>
                        <div className="col-12 col-md-9">
                          <p className="lato">{user?.occupation}</p>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Interests:</h5>
                        </div>
                        <div className="col-12 col-md-9 d-flex">
                          <div className="col-12 col-md-9">
                            <p className="lato">{user?.interest?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Bio:</h5>
                        </div>
                        <div className="col-12 col-md-9">
                          <p
                            className="lato"
                            dangerouslySetInnerHTML={{
                              __html: formdata.userbio,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Mobile Number:</h5>
                        </div>
                        <div className="col-12 col-md-9">
                          <p className="lato">{user?.phone}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {course ? (
                    <div className="container profile_container my-3 py-3">
                      <div className="row pt-2 g-3">
                        {showOrder && showOrder.length > 0 ? (
                          showOrder?.map((item, index) => (
                            <div
                              className="col-lg-4"
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="card" style={{borderRadius:'22px 22px 10px 10px'}}>
                                <div
                                  className="perty"
                                  onClick={() => handleNavigation(item)}
                                >
                                  <div className="imgr card-img-top overflow-hidden">
                                    <img
                                      src={item?.orderItems?.image}
                                      className="d-block w-100 "
                                      alt="Dr. Archika Didi"
                                      style={{
                                        // height: "300px",
                                        aspectRatio: "3/2",

                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="card-body">
                                    <h4
                                      className=" pt-2"
                                      style={{ fontFamily: "myFont" }}
                                    >
                                      {item?.orderItems?.name}
                                    </h4>
                                    <span>
                                      Purchased At :
                                      <Moment format="D MMM YYYY" withTitle>
                                        {item?.createdAt}
                                      </Moment>
                                    </span>
                                    <br />
                                    <span>
                                      Month Validity :
                                      {item?.orderItems?.monthValidity} Months
                                    </span>
                                    <br />
                                    <span>
                                      Type :{" "}
                                      {item?.orderType
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        item?.orderType?.slice(1).toLowerCase()}
                                    </span>
                                    <br />
                                    <span>
                                      Expires On:{" "}
                                      {` ${formatFutureDate(
                                        calculateDaysLeft(item),
                                        item
                                      )}`}
                                      {` ${formatDaysLeft(
                                        calculateDaysLeft(item)
                                      )}`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No data exists</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {music || song || wellness || bhagwat || events ? (
                    <div className="container profile_container my-3 py-3">
                      <div className="row pt-2 g-3">
                        {showOrder && showOrder.length > 0 ? (
                          showOrder?.map((item, index) => (
                            <div
                              className="col-lg-4"
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="card">
                                <div
                                  className="perty"
                                  onClick={() => handleNavigation(item)}
                                >
                                  <div className="imgr card-img-top overflow-hidden">
                                    <img
                                      src={item?.orderItems?.image}
                                      className="d-block w-100 "
                                      alt="Dr. Archika Didi"
                                      style={{
                                        // height: "300px",
                                        aspectRatio: "3/2",

                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="card-body">
                                    <h4
                                      className=" pt-2"
                                      style={{ fontFamily: "myFont" }}
                                    >
                                      {item?.orderItems?.name}
                                    </h4>
                                    <span>
                                      Purchased At :
                                      <Moment format="D MMM YYYY" withTitle>
                                        {item?.createdAt}
                                      </Moment>
                                    </span>
                                    <br />
                                    

                                    <span>
                                      Type :{" "}
                                      {item?.orderType === "music"
                                        ? "Album"
                                        : item?.orderType === "song"
                                        ? "Song"
                                        : item?.orderType
                                            ?.charAt(0)
                                            .toUpperCase() +
                                          item?.orderType
                                            ?.slice(1)
                                            .toLowerCase()}
                                    </span>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No data exists</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {question || thought ? (
                    <div className="container profile_container my-3 py-3">
                      <div className="row pt-2 g-3">
                        {fetchPostData && fetchPostData.length > 0 ? (
                          <>
                            {fetchPostData.map((item, index) => (
                              <PostItem key={item._id} listItem={item} />
                            ))}
                            {hideLoadMore === false && (
                              <div className="container my-3 py-3">
                                <div className="row">
                                  <div className="col-12">
                                    {fetchPostloading ? (
                                      <div className="d-flex justify-content-center mt-5">
                                        <div
                                          className="spinner-border"
                                          role="status"
                                        ></div>
                                      </div>
                                    ) : (
                                      <div
                                        className="pt-5 d-flex justify-content-center"
                                        onClick={() =>
                                          loadmoreThoughts(
                                            question ? "question" : "thought"
                                          )
                                        }
                                      >
                                        <button className="btn shadowbuttons btn-lg text-light cmn-t-shake my-2">
                                          Load More
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="text-center">No data exists</div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {answers ? (
                    <div className="profile_container my-4">
                      <h2 className="fw-bold fs-3 px-4 py-2">My Answers</h2>
                      <hr />
                      <section className="">
                        <ul className="timeline-with-icons mx-5 list-unstyled vertical-line">
                          {Array.isArray(userAnswer) &&
                          userAnswer.length > 0 ? (
                            userAnswer.map((item, index) => (
                              <li
                                key={index}
                                className="timeline-item my-4 vertical-line-color px-4"
                              >
                                <div className="w-50">
                                  {item?.fileUrls?.images?.length > 0 && (
                                    <AppCarousel
                                      id={item._id}
                                      images={item?.fileUrls?.images.map(
                                        (item, index) => item.url
                                      )}
                                    />
                                  )}

                                  {item?.fileUrls?.audio && (
                                    <div className="d-flex justify-content-center mb-5">
                                      <audio
                                        controlsList="nodownload"
                                        controls
                                        src={item?.fileUrls?.audio}
                                      />
                                    </div>
                                  )}

                                  {item?.fileUrls?.video && (
                                    <MediaPlayer
                                      title={`Video by ${item?.createdBy?.name}`}
                                      src={item?.fileUrls?.video}
                                    >
                                      <MediaProvider />
                                      <DefaultVideoLayout
                                        icons={defaultLayoutIcons}
                                      />
                                    </MediaPlayer>
                                  )}
                                </div>
                                <p className="lato mb-0 text-capitalize">
                                  {item?.description}
                                </p>
                                <p className="text-muted mb-2 fw-bold">
                                  <Moment
                                    className="fw-semibold"
                                    format="D MMM YYYY"
                                    withTitle
                                  >
                                    {item?.createdAt}
                                  </Moment>
                                </p>
                              </li>
                            ))
                          ) : (
                            <div className="text-center">No data exists</div>
                          )}
                        </ul>
                        {hasMoreData && (
                          <div className="d-flex justify-center my-2">
                            <button
                              className="btn shadowbuttons btn-lg lato text-light cmn-t-shake mx-auto profile-loadmore my-2"
                              onClick={handleLoadMoreAnswers}
                            >
                              Load More
                            </button>
                          </div>
                        )}
                      </section>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {activeTab == "podcast" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {activeTab == "bhagwat" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {activeTab == "wellness" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {activeTab == "donation" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">...</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoaderPage />
      )}
    </>
  );
}

export default Profile;
