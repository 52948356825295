import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSinglelistBhagwat, getVersesBhagwat } from "../Allslices/BhagwatGitaSlice";
import Moment from "react-moment";
import OrderModal from '../Payments/OrderModal';
import { convertToMins } from "../appUtils";
import MetaData from "../component/layout/MetaData";
import rect74 from '../images/Rectangle74.png'
import ad from '../images/ad.png'
import { getSingleWellnessVideo } from '../Allslices/wellnessSlice';


const WellnessSingleVideo = () => {
    const { singlevideowellness } = useSelector((state) => state.allwellness)

    const { slug } = useParams();



    useEffect(() => {
        dispatch(getSingleWellnessVideo(slug))
    }, [])
  const dispatch = useDispatch();




  const audioRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (singlevideowellness && audioRef.current) {
      audioRef.current.src = singlevideowellness.audiofile;
    }
  }, [singlevideowellness]);

  const handlePlay = () => {
    audioRef.current.play();
    setPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setPlaying(false);
  };

  const handleTogglePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (time) => {
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleBackward = () => {
    handleSeek(currentTime - 10); // Adjust backward duration as needed
  };

  const handleForward = () => {
    handleSeek(currentTime + 10); // Adjust forward duration as needed
  };
  // const handleProgressClick = (e) => {
  //   const rect = e.target.getBoundingClientRect();
  //   const offsetX = e.clientX - rect.left;
  //   const percentage = (offsetX / rect.width);
  //   const newTime = percentage * duration;
  //   handleSeek(newTime);
  // };
  return (
    <>
      <MetaData title={singlevideowellness?.title} />


      {/* banner section */}
      <div className="rect57bg blogimg mb-2" style={{
        backgroundImage: `url(${singlevideowellness?.thumbnail_image})`,
        backgroundSize: "cover",
        // filter: "blur(2px)"
      }}>
        <div className="container blogsub ">
          <div className="row text-center ">
            <div className="col-lg-12 col-sm-12">
              <h2 className="text-dark" style={{ fontFamily: "myFont!important", paddingTop:"80px" }}>
                {singlevideowellness?.title}
              </h2>
            </div>
          </div>
        </div>
        <div className="wellnesssinglevideo" style={{padding:"90"}}>
          {singlevideowellness?.contentType === "text" ? (
            <img
              src={singlevideowellness?.thumbnail_image}
              alt="Dr. Archika Didi"
              className=""
              style={{
                borderRadius: "20px",
                boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                              
              }}
            />
          ) : singlevideowellness?.contentType === "audio" ? (
            <>
              <img
                src={singlevideowellness?.thumbnail_image}
                alt="Dr. Archika Didi"
                className=""
                style={{
                  borderRadius: "20px",
                  boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                  height: "425px"
                }}
              />
              <div className="position-absolute top-100 w-50">
                <>
                  <div
                    className="d-flex justify-content-between align-items-center gap-3"
                    style={{
                      width: "100%",
                      backgroundColor: "#D9D9D9E5",
                      padding: "2rem",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center gap-3" style={{ width: "8rem" }}>
                      <span onClick={handleBackward}>
                        <i className={`fas fa-backward fs-4`} />
                      </span>
                      <span onClick={handleTogglePlayPause}>
                        {isPlaying ? (
                          <i className={`fas fa-pause fs-4`} />
                        ) : (
                          <i className={`fas fa-play fs-4`} />
                        )}
                      </span>
                      <span onClick={handleForward}>
                        <i className={`fas fa-forward fs-4`} />
                      </span>
                    </div>

                    <audio
                      src={singlevideowellness?.audiofile}
                      ref={audioRef}
                      onClick={handleTogglePlayPause}
                      onTimeUpdate={handleTimeUpdate}
                      onLoadedMetadata={handleLoadedMetadata}
                    ></audio>

                    <div className="progress-bar-container  " >
                      <div className="progress-bar " style={{ width: `${(currentTime / duration) * 100}%`, backgroundColor: "black" }}></div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "171px" }}>
                      <div className="d-flex gap-1 ">
                        <span>{convertToMins(currentTime)}</span>/
                        <span>{convertToMins(duration)}</span>
                      </div>
                    </div>


                  </div>
                </>
              </div>
            </>
          ) : (
            <iframe
              src={singlevideowellness?.videourl}
              title={singlevideowellness?.title}
              className="singlevideoiframe singlewellnessiframe"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{
                borderRadius: "20px",
                boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)"
              }}
            ></iframe>
          )}
        </div>
      </div>
      {/* banner section end */}
      {/* text section all */}
      <section>
        <div className="container">
          <div className="textrt ">
            <p
              className="contyu mt-5 text-center"
              style={{ fontSize: "18px" , padding:"75px 0 35px 0"}}
              dangerouslySetInnerHTML={{ __html: singlevideowellness?.description }}
            ></p>
          </div>
        </div>
      </section>

      {/* <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-lg d-sm-5 lato rounded-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          style={{ backgroundColor: "#ff9933", padding:"10px 75px ", color: "white",fontSize:"23px" }}
        >
          BUY
        </button>
      </div> */}
      {/* <OrderModal /> */}
      <br /><br />
    </>
  );
};

export default WellnessSingleVideo;
