import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const filters = {
    categoryId: '',
    language: '',
    sort: '',
    contentType: '',
    text: '',
    isSearched:false
}
const initialState = {

    isLoading: false,
    error: null,
    wisdom: null,
    currentPage: 0,
    limit: 8,
    totalwisdom: 0,
    wisdombanner: null,
    singlewisdomlist: null,
    wisdomCategories: null,
    Languages: null,
    Filters: filters
}

export const getwisdomList = createAsyncThunk(
    'wisdom/allWisdomLists',
    async ({ page, limit }, { dispatch, getState, rejectWithValue }) => {
        const { wisdom: { Filters: { categoryId, language, sort, contentType, text } } } = getState();
        try {
            dispatch(wisdomstart());
            const response = await axios.get(`wisdom/allWisdomLists?page=${page ?? + 1}&limit=${limit ?? ""}&categoryId=${categoryId ?? ""}&contentType=${contentType ?? ""}&sort=${sort ?? ""}&language=${language ?? ""}&text=${text ?? ""}`)
            dispatch(wisdomSuccess((response?.data)))
            return response?.data
        } catch (error) {
            dispatch(wisdomFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)


        }
    }
)

export const getwisdomConfig = createAsyncThunk(
    'config/get_wisdombanner',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(wisdomBannerstart());
            const response = await axios.get(`config/get_wisdombanner`)
            dispatch(wisdomBannerSuccess(response?.data?.wisdombanner))

        } catch (error) {
            dispatch(wisdomBannerFailure(error.response.data.message))
        }
    }
)

export const getwisdomCategory = createAsyncThunk(
    'wisdom/allWisdomCategoryr',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(wisdomCategoriesstart());
            const response = await axios.get(`wisdom/allWisdomCategory?page=${1}&limit=${1000}`)
            dispatch(wisdomCategoriesSuccess(response?.data?.wisdomCategories))

        } catch (error) {
            dispatch(wisdomCategoriesFailure(error.response.data.message))
        }
    }
)
export const getsinglewisdomlist = createAsyncThunk(
    'wisdom/singlewidomlist',
    async (slug, { dispatch, rejectWithValue }) => {
        try {
            dispatch(singlewisdomliststart());
            const response = await axios.get(`wisdom/${slug}`)
            dispatch(singlewisdomlistsuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(singlewisdomlistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)


export const getLanguages = createAsyncThunk(
    'languages/get_languages',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(languagesStart());
            const response = await axios.get(`languages/get_languages`)
            dispatch(languagesSuccess(response?.data?.settingItems))

        } catch (error) {
            dispatch(languagesFailure(error.response.data.message))
        }
    }
)







const wisdomSlice = createSlice({
    name: 'wisdom',
    initialState,
    reducers: {
        wisdomstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        wisdomSuccess(state, action) {
            state.wisdom = action.payload.wisdomLists;
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
            state.totalwisdom = action.payload.totalwisdom;
            state.isLoading = false;
        },
        wisdomFailure(state, action) {
            state.wisdom = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        wisdomBannerstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        wisdomBannerSuccess(state, action) {
            state.wisdombanner = action.payload;
            state.isLoading = false;
        },
        wisdomBannerFailure(state, action) {
            state.wisdombanner = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        singlewisdomliststart(state) {
            state.isLoading = true;
            state.error = null;
        },
        singlewisdomlistsuccess(state, action) {
            state.singlewisdomlist = action.payload;
            state.isLoading = false;
        },
        singlewisdomlistfailure(state, action) {
            state.singlewisdomlist = null;
            state.isLoading = false;
            state.error = action.payload;
        }
        ,
        wisdomCategoriesstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        wisdomCategoriesSuccess(state, action) {
            state.wisdomCategories = action.payload;
            state.isLoading = false;
        },
        wisdomCategoriesFailure(state, action) {
            state.wisdomCategories = null;
            state.isLoading = false;
            state.error = action.payload;
        }
        ,
        languagesStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        languagesSuccess(state, action) {
            state.Languages = action.payload;
            state.isLoading = false;
        },
        languagesFailure(state, action) {
            state.Languages = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        filtersUpdate(state, action) {
            const { categoryId, language, sort, contentType, text , isSearched } = action.payload;
            if (categoryId !== undefined) {
                state.Filters.categoryId = categoryId;
            }
            if (language !== undefined) {
                state.Filters.language = language;
            }
            if (sort !== undefined) {
                state.Filters.sort = sort;
            }
            if (contentType !== undefined) {
                state.Filters.contentType = contentType;
            }
            if (text !== undefined) {
                state.Filters.text = text;
            }
            if(isSearched !== undefined){
                state.Filters.isSearched = isSearched
            }
                
            
        }
    }
})


export const { filtersUpdate, wisdomstart, wisdomSuccess, wisdomFailure, wisdomBannerstart, wisdomBannerSuccess, wisdomBannerFailure, singlewisdomliststart, singlewisdomlistsuccess, singlewisdomlistfailure, wisdomCategoriesstart, wisdomCategoriesSuccess, wisdomCategoriesFailure, languagesStart, languagesSuccess, languagesFailure } = wisdomSlice.actions;

export default wisdomSlice.reducer;
