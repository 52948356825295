import { useState, useEffect } from "react";
import sc from "../images/schapterbg.png";
import { useDispatch, useSelector } from "react-redux";
import { getSinglelistBhagwat, getVersesBhagwat } from "../Allslices/BhagwatGitaSlice";
import { useParams } from "react-router-dom";
import SmallLoader from "./SmallLoader";
import { useNavigate } from "react-router-dom";
import MetaData from "../component/layout/MetaData";
import crown from "../images/crown.png"
import OrderModal from '../Payments/OrderModal';
import {getmyOrder } from "../Allslices/orderSlice";
import Moment from "react-moment";
 



const SingleChapter = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const { BhagwatSinglelist } = useSelector((state) => state.BhagwatGita);
  const { BhagwatVerses } = useSelector((state) => state.BhagwatGita);


  useEffect(() => {

    dispatch(getSinglelistBhagwat(slug));
    dispatch(getVersesBhagwat(slug));
  }, []);



  const [showAllCards, setShowAllCards] = useState(false);
  const handleLoadMore = () => {
    setShowAllCards(true);
  };

  const Navigate2 = useNavigate();
  const handleNavigatetoSubVerse = (slug) => {
    Navigate2(`/bhagwatgeeta/${slug}`);
  };
  const maxLength = 20;
  const titlemaxLength = 25;


  const { orderAll } = useSelector((state) => state?.order);

  const orderType = "bhagwat";

  const productId = [];

  const[orderId,serOrderId]=useState("")

  useEffect(()=>{
    orderAll.forEach((item)=>{
      return serOrderId(item?.orderItems?.product)
    })
  })

  const [buynow, setbuynow] = useState(false);
const[modelOpen,setModelOpen]=useState("")
const[singleVideo,setSingleVideo]=useState({})



const handlebuynow = (proId) => {
  const singleV = BhagwatSinglelist?.verses?.find((data) => data?._id === proId);
  setSingleVideo(singleV);

  const checkID = orderAll.some((orderItem) => orderItem?.orderItems?.product === proId);


  if (checkID) {
    setbuynow(false);
    handleNavigatetoSubVerse(singleV.slug);
    return;
  } 

  if (singleV?.isPaid === false) {
    setbuynow(false);
    handleNavigatetoSubVerse(singleV.slug);
    return;
  }

  // If the product is not purchased and not free
  setbuynow(true);
  setModelOpen(0); // Open the modal for buying
};

useEffect(() => {
  if (buynow && singleVideo && modelOpen.length === 0) {
    handleNavigatetoSubVerse(singleVideo.slug);
  }
}, [buynow, singleVideo, modelOpen]);

useEffect(() => {
  dispatch(getmyOrder());
}, [dispatch]);







  return (
    <>
      <MetaData title={BhagwatSinglelist?.title} />
      {BhagwatSinglelist ? (
        <>
          <div className="container d-flex  align-items-center flex-column p-5 lh-lg text-center">
            <h3 className="pt-5 text-uppercase lato" style={{ color: "#ff9f54" }}>
              {BhagwatSinglelist?.chapter_number}
            </h3>
            <div
              className="my-5"
              style={{
                backgroundImage: `url(${sc})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "18vh",
                fontSize: "23px",
              }}
            >
              <h1 className="text-capitalize lato fw-bold">{BhagwatSinglelist?.title}</h1>
              <p className="pt-5 lato">{BhagwatSinglelist?.short_description} </p>
            </div>
          </div>
        </>
      ) : (
        <SmallLoader />
      )}

<section className="">
        <div className="container artvid ">
          <div className="row"  >
            {Array.isArray(BhagwatSinglelist?.verses) && BhagwatSinglelist.verses.map((item, index) => (
              <div key={index}  className={`col-md-6 col-sm-12 col-lg-3 item my-2  
        ${!showAllCards && index >= 4 ? 'd-none' : ''}`}
                 >
                 
                <div className="card item-card rounded-4 card_height1" data-bs-toggle={modelOpen === 0 ? "modal" : undefined}
                        data-bs-target={modelOpen === 0 ? "#staticBackdrop" : undefined}
                        onClick={() => {
                        handlebuynow(item._id);}}  >
  {item?.ispaid===true && !orderAll.some(orderItem => orderItem?.orderItems?.product === item._id) && (
    <img
      src={crown}
      alt="Dr. Archika Didi"
      className="position-absolute end-0"
      height={35}
    />
  )}


                  <img loading="lazy" src={item?.thumbnail_image} alt="Dr. Archika Didi"  
                          style={{ borderRadius: "20px 20px 0 0",aspectRatio:"3/2" }} />
                  <div className="d-flex p-3 justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <a href="#"></a>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <span className="text-secondary fw-bold">
                        {item.contentType === "audio" ? "Audio" : item.contentType === "video" ? "Video" : "Text"}
                      </span>
                      <a href="#">
                        <i className={`fa-solid ${item.contentType === "audio" ? 'fa-headphones' :
                          item.contentType === 'video' ? 'fa-play' : 'fa-book'
                          } text-dark fs-4`}></i>
                      </a>
                    </div>
                  </div>
                  <div className="container pb-4">
                    <h4 className="m-2 text-capitalize" style={{ fontFamily: "Playfair" }}>
                      {item?.title}
                      
                    </h4>
                  
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



      <br /><br />

      {!showAllCards && BhagwatSinglelist?.verses.length > 4 && (
        <div className="col-12">
          <div className="pt-3 d-flex justify-content-center py-5">
            <button
              className="btn btn-orange btn-lg text-light rounded-pill cmn-t-shake"
              style={{ backgroundColor: "#FF9933!important" }}
              onClick={handleLoadMore}
            >
              LOAD MORE
            </button>
          </div>
        </div>
      )}

<OrderModal orderType={orderType} product={buynow ? singleVideo : ""} navigate={true} isExpired={false}/>


      <br /><br />

      
    </>
  );
};

export default SingleChapter;
