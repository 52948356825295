import React from "react";
import { useState, useEffect,useRef } from "react";
import { useParams,useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Moment from "react-moment";

import MetaData from "../component/layout/MetaData";
import img3 from "../images/Rectangle 443.png";
import img_3 from "../images/Rectangle 444.png";
import img__3 from "../images/Rectangle 445.png";
import img4 from "../images/eve_2.jpg";
import img5 from "../images/eve1.jpg";
import img6 from "../images/eve_3.jpg";
import img7 from "../images/Rectangle 450.png";
import BG from "../images/Rectangle 413.png";
import {getEventOtherConfig} from "../Allslices/EventConfigSlice";
import {getEventList} from "../Allslices/EventConfigSlice";

import { useSelector, useDispatch } from "react-redux";
function Otherevents() {
  const { EventOtherConfig } = useSelector((state) => state.Event_Config);

  const { EventAllList } = useSelector((state) => state.Event_Config);


  const dispatch = useDispatch();
   useEffect(() => {
    dispatch(getEventOtherConfig());
    dispatch(getEventList());

  }, []);
  
  const Navigate = useNavigate();

  const { slug } = useParams();

  const annualsingleEvents = (slug) => {
    Navigate(`/eventblog/${slug}`);
  };
  let sliderRef1 = useRef();

  let next1 = () => {
    sliderRef1.slickNext();
  };
  let previous1 = () => {
    sliderRef1.slickPrev();
  };
  const settings1 = {
    dots: true,
    infinite: true,
    autoscroll: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  return (
    <>
<MetaData title="Other Events" />
      <div
        className="blogimg evenetbgb"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(75, 100, 100, 1), rgba(75, 100, 100, 0.44)), url('${EventOtherConfig?.background_image}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          
          

        }}
      >
        <div className="container event">
          <div className="row">
            <div className="col-lg-12 col-sm-12 ">
              <h1 className="text-white d-flex align-items-end justify-content-center mt-3" style={{ fontSize: "82px", fontFamily: "Playfair Display", color: "rgba(255, 255, 255, 1)", fontWeight: 400 }}>
                {EventOtherConfig?.heading}
              </h1>
            </div>
          </div>
        </div>
      </div>



      <section>
        <div className=" container beloved">
          <h1 className="text-center pt-5">
            <span className="" style={{ fontFamily: "playfair", fontSize: "50px", color: "rgba(0, 0, 0, 1)", fontWeight: 900 }}>
              Latest Upcoming
            </span>
          </h1>
        </div>
      </section>


      <section>
        <div className="container-fluid beloved pt-5">
          <div className="container">
            <div className="row mb-5 d-flex justify-content-center">
              <div className="col-md-6 col-sm-12 col-lg-4" style={{ cursor: "pointer" }}>

                <div className="card item-card text-dark border-0" >
                  <img loading="lazy" src={img3} alt="Dr. Archika Didi" width={"100%"}
                    height={"293px"} />
                  <div className="card-text-overlay">
                    <p className="text-white " style={{ position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center", fontFamily: "Playfair Display", fontSize: "34px", fontWeight: 700 }}>Meditation</p>
                  </div>


                  <div className="card-body" >
                    <h3 className="" style={{ fontFamily: "Lato", fontSize: "28px", color: "rgba(0, 0, 0, 1)", fontWeight: 600 }}>Designer,Web Developer &
                      Digital artist</h3>

                    <h5 className="" style={{ fontSize: "18px", color: "rgba(0, 0, 0, 1)", fontFamily: "Lato", fontWeight: 600 }}><i className="fa-solid fa-location-dot"></i> Bakkarwala Marg, Nangloi-Najafgarh Road</h5>

                    <p className=" pt-2" style={{ fontSize: "19px", color: "rgba(0, 0, 0, 1)", fontFamily: "Lato", fontWeight: 600 }}> Velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh.</p>
                  </div>
                  <div className="d-flex align-items-start">
                    <button className="btn btn-warning btn-lg text-light rounded-5 mt-2 mx-3 mb-5" style={{ backgroundColor: 'rgba(255, 153, 51, 1)', fontFamily: "Lato", fontSize: "18px" }}>
                      JOIN EVENT
                    </button>
                  </div>
                </div>

              </div>
              <div className="col-md-6 col-sm-12 col-lg-4" style={{ cursor: "pointer" }}>

                <div className="card item-card text-dark border-0" >
                  <img loading="lazy" src={img_3} alt="Dr. Archika Didi" width={"100%"}
                    height={"293px"} />
                  <div className="card-text-overlay">
                    <p className="text-white fw-700" style={{ position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center", fontFamily: "Playfair Display", fontSize: "34px", }}> <span style={{ fontSize: "50px", }}>Yoga</span></p>
                  </div>


                  <div className="card-body" >
                    <h3 className="" style={{ fontFamily: "Lato", fontSize: "28px", color: "rgba(0, 0, 0, 1)", fontWeight: 600 }}>Designer,Web Developer &
                      Digital artist</h3>

                    <h5 className="" style={{ fontSize: "18px", color: "rgba(0, 0, 0, 1)", fontFamily: "Lato", fontWeight: 600 }}><i className="fa-solid fa-location-dot"></i> Bakkarwala Marg, Nangloi-Najafgarh Road</h5>

                    <p className="fw-pt-2" style={{ fontSize: "19px", color: "rgba(0, 0, 0, 1)", fontFamily: "Lato", fontWeight: 600 }}> Velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh.</p>
                  </div>
                  <div className="d-flex align-items-start">
                    <button className="btn btn-warning btn-lg text-light rounded-5 mt-2 mx-3 mb-5" style={{ backgroundColor: 'rgba(255, 153, 51, 1)', fontFamily: "Lato", fontSize: "18px" }}>
                      JOIN EVENT
                    </button>
                  </div>
                </div>

              </div>
              <div className="col-md-6 col-sm-12 col-lg-4" style={{ cursor: "pointer" }}>

                <div className="card item-card text-dark border-0" >
                  <img loading="lazy" src={img__3} alt="Dr. Archika Didi" width={"100%"}
                    height={"293px"} />
                  <div className="card-text-overlay">
                    <p className="text-white fw-700" style={{ position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center", fontFamily: "Playfair Display", fontSize: "34px", }}><span style={{ fontSize: "50px" }}>Spiritual</span></p>
                  </div>


                  <div className="card-body" >
                    <h3 className="" style={{ fontFamily: "Lato", fontSize: "28px", color: "rgba(0, 0, 0, 1)", fontWeight: 600 }}>Designer,Web Developer &
                      Digital artist</h3>

                    <h5 className="" style={{ fontSize: "18px", color: "rgba(0, 0, 0, 1)", fontFamily: "Lato", fontWeight: 600 }}><i className="fa-solid fa-location-dot"></i> Bakkarwala Marg, Nangloi-Najafgarh Road</h5>

                    <p className=" pt-2" style={{ fontSize: "19px", color: "rgba(0, 0, 0, 1)", fontFamily: "Lato", fontWeight: 600 }}> Velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh.</p>
                  </div>
                  <div className="d-flex align-items-start">
                    <button className="btn btn-warning btn-lg text-light rounded-5 mt-2 mx-3 mb-5" style={{ backgroundColor: 'rgba(255, 153, 51, 1)', fontFamily: "Lato", fontSize: "18px" }}>
                      JOIN EVENT
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid " style={{ background: 'linear-gradient(180deg, #E5F0FB 32%, rgba(229, 240, 251, 0) 100%)' }}>
          <div className="py-5">
            <h1 className="text-center " style={{ fontSize: "62px", fontFamily: "Playfair Display", color: "rgba(0, 0, 0, 1)", fontWeight: 700 }}>List of all events</h1>
          </div>
          <div className="container">
            

          <div className="row">
    <div className="col-md-6 col-sm-12 col-lg-12 mb-2">
      <div className="card item-card text-dark" >
        <div className="d-flex">
          <img loading="lazy" src={img7} alt="Dr. Archika Didi"  height={200} className="my-4 mx-5 rounded-2 d-none d-sm-block" />

          <div className="card-body" >
            <h1 style={{ fontFamily: "Playfair Display", fontSize: "34px", color: "rgba(0, 0, 0, 1)", fontWeight: 700 }}>Marketing Matters!</h1>
            <h5 className="" style={{ color: 'rgba(253, 110, 18, 1)', fontWeight: 700 }}>9:30 am - 10:30 am</h5>
            <h4 className="fs-5" style={{ fontWeight: 400 }}>How you transform your business as technology, consumer, habits industry dynamics change? Find out from those leading the charge. How you transform</h4>
            <p className="" style={{ fontWeight: 600 }} > <i className="fa-solid fa-location-dot"></i> Bakkarwala Marg, Nangloi-Najafgarh Road</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-sm-12 col-lg-12 mb-2">
      <div className="card item-card text-dark" >
        <div className="d-flex">
          <img loading="lazy" src={img7} alt="Dr. Archika Didi"  height={200} className="my-4 mx-5 rounded-2 d-none d-sm-block" />

          <div className="card-body" >
            <h1 style={{ fontFamily: "Playfair Display", fontSize: "34px", color: "rgba(0, 0, 0, 1)", fontWeight: 700 }}>Marketing Matters!</h1>
            <h5 className="" style={{ color: 'rgba(253, 110, 18, 1)', fontWeight: 700 }}>9:30 am - 10:30 am</h5>
            <h4 className="fs-5" style={{ fontWeight: 400 }}>How you transform your business as technology, consumer, habits industry dynamics change? Find out from those leading the charge. How you transform</h4>
            <p className="" style={{ fontWeight: 600 }} > <i className="fa-solid fa-location-dot"></i> Bakkarwala Marg, Nangloi-Najafgarh Road</p>
          </div>
        </div>
      </div>
    </div>
  </div>


            <div className="mt-5  text-center">
              <button
                className="btn btn-warning btn-lg text-light rounded cmn-t-shake py-3 px-5 rounded-pill "
                style={{ backgroundColor: "#FF9933" }}
              >
                LOAD MORE
              </button>
            </div>


          </div>
        </div>

      </section>

      <section>
        <div className="cotainer-fluid " style={{ marginTop: "150px" }}>
          <div className="d-flex align-items-end justify-content-center">
            <h1 className='text-center px-2' style={{ fontFamily: "myfont", fontSize: "70px", }} >Latest Articles</h1></div>
        </div>
      </section>



      <section>
        <div className="container-fluid beloved pt-5" style={{ backgroundColor: "#F9F9F9" }}>
          <div className='mb-5 text-center'>
            

          </div>

          <div className="container">
            <div className="row mb-5 d-flex justify-content-center mx-2">
            <Slider
              ref={slider => {
                sliderRef1 = slider;
              }}
              {...settings1}
            >
            {Array.isArray(EventAllList) && EventAllList.filter(item => item.eventType === "Other" ).slice(0, 15).map((item, index) => (item.eventsBloglist.map((element, innerIndex) => (
        <div key={`${index}-${innerIndex}`} className="col-md-6 col-sm-12 col-lg-4" style={{ cursor: "pointer" }}  onClick={() => annualsingleEvents(element?.slug)}>
            <div className="card item-card text-dark mx-3 " style={{ marginBottom: "20px" }}>
                <img
                    src={element?.thumbnail_image}
                    alt="Dr. Archika Didi"
                    width={"100%"}
                    height={230}
                />
                <div className="card-body" style={{ textAlign: "left" }}>
                    <p className="" style={{ fontStyle: "italic" }}>  
                                      <Moment
                                        className="fw-semibold"
                                        format="D MMM YYYY"
                                        withTitle
                                      >
                                        {element?.createdAt}
                                      </Moment></p>

                    <h5 className="fs-3" style={{ fontWeight: 400 }}>{element?.title}</h5>
                    <p className="" style={{ color: "gray" }}>{element?.shortdescription}</p>
                </div>
                <button className="btn btn-lg text-light text-black fw-400 d-flex align-items-start border-1 mx-2 mb-3" style={{ width: 'fit-content' }}>
                    READ MORE
                </button>
            </div>
        </div>
    ))
))}

            </Slider>
            <div className="d-flex justify-content-center gap-5 mt-5  ">

<button className="button text-white p-3 btnstyle " onClick={previous1} style={{ backgroundColor: "#ffbe59", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: "50%", fontSize: "18px", fontWeight: "bold" }}>
  <i className="fa-solid fa-chevron-left"></i>
</button>
<button className="button text-white p-3 btnstyle" onClick={next1} style={{ backgroundColor: "#ffbe59", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: "50%", fontSize: "18px", fontWeight: "bold" }}>
  <i className="fa-solid fa-chevron-right"></i></button>

</div>




            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default Otherevents;
