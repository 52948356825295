import { React, useEffect, useRef, useState } from "react";

import { useLocation, useParams, useNavigate } from "react-router-dom";

import WisdomAudioPlayer from "./WisdomAudioPlayer";
import Moment from "react-moment";
import { convertToMins } from "../appUtils";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEventList } from "../Allslices/EventConfigSlice";
import MetaData from "../component/layout/MetaData";

export default function Wisdom_Article() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
 const { EventSingle_List } = useSelector((state) => state.Event_Config);
 
   useEffect(() => {
    dispatch(getSingleEventList(slug));
   
  }, []);

  

  const url = window.location.href;

  const maxLength = 180;

  return (
    <div>
      <MetaData title="AnnualSingle" />
      <>
        <div
          className=" blogimg mb-2"
          style={{
         
            backgroundImage: `linear-gradient(45deg, rgba(249, 200, 124, 0.3), rgba(249, 200, 123, 21)), url(${EventSingle_List?.thumbnail_image})`,backgroundSize: "cover",}}>
         
          <div className="container blogsub ">
            <div className="row text-center p-5">
              <div className="col-lg-12 col-sm-12">
                <h1 className=" text-dark" style={{ fontFamily: "playfair" }}>
                  {EventSingle_List?.title}
                </h1>
              </div>
            </div>
          </div>
              

          <div className="imagesee pt-5 mt-5">
         <img loading="lazy" src={EventSingle_List?.thumbnail_image} alt="Dr. Archika Didi" srcset=""  style={{
                    borderRadius: "20px",
                    boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)"
                    ,aspectRatio:"3/2"
                  }} />
          </div>

          
        </div>
      
        <section>
          <div className="container pt-3">
            <div className="textrt pt-5 mt-5">
              <div className="row  icotext text-center">
                <div className="col-lg-6">
                  <p
                    style={{ textTransform: "capitalize", paddingTop: "10px" }}
                  >
                    {EventSingle_List?.contentType} {" "}
                    <Moment format="D MMM YYYY" withTitle>
                      {EventSingle_List?.created_at}
                    </Moment>
                  </p>
                </div>
                <div className="col-lg-6">
                  <a href="#" className="p-1">
                    <FacebookShareButton
                      url={"https://archikadidi.com"}
                      hashtag="#ArchikaDidiBlog"
                      quote={"CampersTribe - World is yours to explore"}
                    >
                      <FacebookIcon size={45} round={true} />
                    </FacebookShareButton>
                  </a>
                
                  <a href="#" className="p-1">
                    <WhatsappShareButton
                      appId=""
                      title="Hi I am happy to share Blogs by Archika Didi"
                      url={url}
                      hashtag="#ArchikaDidiBlog"
                    >
                      <WhatsappIcon size={45} round={true} />
                    </WhatsappShareButton>
                  </a>
                  <a href="#" className="p-1">
                    <TwitterShareButton
                      appId=""
                      title="Hi I am happy to share Blogs by Archika Didi"
                      hashtag="#ArchikaDidiBlog"
                      url={url}
                    >
                      <XIcon size={45} round={true} />
                    </TwitterShareButton>
                  </a>
                  <a href="#" className="p-1">
                    <TelegramShareButton
                      title="Hi I am happy to share Blogs by Archika Didi"
                      url={url}
                    >
                      <TelegramIcon size={45} round={true} />
                    </TelegramShareButton>
                  </a>
                </div>
              </div>
            
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-5">
            <div className="row justify-content-center mt-5">
              <div className="col-lg-8 pb-5 col-md-12 col-sm-12 text-center">
                <p
                  style={{ color: "#807F7F", fontSize:"20px" }}
                  dangerouslySetInnerHTML={{
                    __html: EventSingle_List?.shortdescription,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>

      
      </>
    </div>
  );
}
