import { React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSinglelistBhagwat, getVersesBhagwat } from "../Allslices/BhagwatGitaSlice";
import Moment from "react-moment";
import OrderModal from '../Payments/OrderModal';

import { convertToMins } from "../appUtils";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
import MetaData from "../component/layout/MetaData";
import crown from "../images/crown.png"

const SingleVerses = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();


  const { BhagwatSinglelist } = useSelector((state) => state.BhagwatGita);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const { BhagwatVerses } = useSelector((state) => state.BhagwatGita);
  useEffect(() => {
    dispatch(getSinglelistBhagwat(slug));
    dispatch(getVersesBhagwat(slug));
  }, []);
  const audioRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  useEffect(() => {
    if (BhagwatVerses && audioRef.current) {
      audioRef.current.src = BhagwatVerses.audiofile;
    }

  }, [BhagwatVerses]);

  const handlePlay = () => {
    audioRef.current.play();
    setPlaying(true);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (time) => {
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setPlaying(false);
  };

  const [buynow, setbuynow] = useState(false);

  const orderType = "bhagwat";
  const handleBackward = () => {
    handleSeek(currentTime - 10); // Adjust backward duration as needed
  };

  const handlebuynow = () => {
    setbuynow(true);
  };
  const handleForward = () => {
    handleSeek(currentTime + 10); // Adjust forward duration as needed
  };

  const handleTogglePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };
  const url = window.location.href;

  return (
    <>
      <MetaData title={BhagwatVerses?.title} />
      {/* banner section */}
      <div className="rect57bg blogimg mb-2" style={{
        backgroundImage: `linear-gradient(45deg, rgba(249, 200, 124, 0.3), rgba(249, 200, 123, 21)
), url(${BhagwatVerses?.thumbnail_image})`,
        backgroundSize: "cover",
      }}>
        <div className="container blogsub ">
          <div className="row text-center p-5">
            <div className="col-lg-12 col-sm-12">

              <h1 className=" text-dark" style={{ fontFamily: "playfair" }}>
                
              </h1>
          
            </div>
          </div>
        </div>
        <div className="imagesee pt-5 mt-5 ">
          {BhagwatVerses?.contentType === "text" ? (
            <img
              src={BhagwatVerses?.thumbnail_image}
              alt="Dr. Archika Didi"
              className=""
              style={{
                borderRadius: "20px",
                boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)", aspectRatio: "3/2"
              }}
            />
          ) : BhagwatVerses?.contentType === "audio" ? (
            <>
              <img
                src={BhagwatVerses?.thumbnail_image}
                alt="Dr. Archika Didi"
                className=""
                style={{
                  borderRadius: "20px",
                  boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)", aspectRatio: "3/2"
                }}
              />
              <div className="position-absolute top-100 w-50">
                <>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      width: "100%",
                      backgroundColor: "#D9D9D9E5",
                      padding: "2rem",
                      gap: "15px"
                    }}
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "8rem", gap: "15px" }}
                    >
                      <span onClick={handleBackward}>
                        <i className={`fas fa-backward fs-4`} />
                      </span>
                      <span onClick={handleTogglePlayPause}
                      // onClick={
                      //   !currentTrack?.isPaid && currentTrack
                      //     ? handlePlayPause
                      //     : null
                      // }
                      >
                        {isPlaying ? (
                          <i
                            className={`fas fa-pause fs-4 `}
                            onClick={() => {
                              handlePause();
                            }}
                          ></i>
                        ) : (
                          <i
                            className={`fas fa-play fs-4 `}
                            onClick={() => {
                              handlePlay();
                            }}
                          ></i>
                        )}
                      </span>
                      <span onClick={handleForward}>
                        <i className={`fas fa-forward fs-4`} />
                      </span>
                    </div>
                    <audio
                      src={BhagwatVerses?.audiofile}
                      ref={audioRef}
                      // onClick={() => {
                      //     if (isPlaying) {
                      //         handlePause();
                      //     } else {
                      //         handlePlay();
                      //     }
                      // }}
                      onClick={handleTogglePlayPause}
                      onTimeUpdate={handleTimeUpdate}
                      onLoadedMetadata={handleLoadedMetadata}
                    ></audio>

                    <div className="progress-bar-container" style={{ marginTop: "12px" }}>
                      <div className="progress-bar" style={{ width: `${(currentTime / duration) * 100}%`, backgroundColor: "black" }} id="progress-bar"></div>
                    </div>

                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "171px", backgroundColor: "transparent" }}
                    >
                      <div className="d-flex gap-1 text-secondary">
                        {/* <span>
                                                    {audioRef.current
                                                        ? convertToMins(audioRef.current.currentTime)
                                                        : "0:00"}
                                                </span>
                                                /
                                                <span>
                                                    {audioRef.current?.duration
                                                        ? convertToMins(audioRef.current.duration)
                                                        : "0:00"}
                                                </span> */}
                        <span>{convertToMins(currentTime)}</span>/
                        <span>{convertToMins(duration)}</span>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </>
          ) : (
            <iframe
              width="560"
              height="315"
              src={BhagwatVerses?.videourl}
              title={BhagwatVerses?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{
                borderRadius: "20px",
                boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)"
              }}
            ></iframe>
          )}


        </div>
      </div>
      {/* banner section end */}

      {/* text section all */}
      <section>
        <div className="container pt-3">
          <div className="textrt pt-5 mt-5">
            <div className="row  icotext text-center">
              <div className="col-lg-6">
                <p>
                  {BhagwatVerses?.contentType} |{" "}
                  <Moment format="D MMM YYYY" withTitle>
                    {BhagwatVerses?.created_at}
                  </Moment>
                </p>
              </div>
              <div className="col-lg-6">
                <a href="#" className="p-1">
                  <FacebookShareButton
                    url={"https://archikadidi.com"}
                    hashtag="#ArchikaDidiBlog"
                    quote={"CampersTribe - World is yours to explore"}
                  >
                    <FacebookIcon size={45} round={true} />
                  </FacebookShareButton>
                </a>
                {/* <a href="#" className="p-1">
                <FacebookMessengerShareButton
                  appId=""
                  title="Share on Messenger"
                  url={url}
                  hashtag="#ArchikaDidiBlog"
                >
                  <FacebookMessengerIcon size={45} round={true} />
                </FacebookMessengerShareButton>
              </a> */}
                {/* <a href="#" className="p-1">instagram</a> */}
                <a href="#" className="p-1">
                  <WhatsappShareButton
                    appId=""
                    title="Hi I am happy to share Blogs by Archika Didi"
                    url={url}
                    hashtag="#ArchikaDidiBlog"
                  >
                    <WhatsappIcon size={45} round={true} />
                  </WhatsappShareButton>
                </a>
                <a href="#" className="p-1">
                  <TwitterShareButton
                    appId=""
                    title="Hi I am happy to share Blogs by Archika Didi"
                    hashtag="#ArchikaDidiBlog"
                    url={url}
                  >
                    <XIcon size={45} round={true} />
                  </TwitterShareButton>
                </a>
                <a href="#" className="p-1">
                  <TelegramShareButton
                    title="Hi I am happy to share Blogs by Archika Didi"
                    url={url}
                  >
                    <TelegramIcon size={45} round={true} />
                  </TelegramShareButton>
                </a>

              </div>
            </div>

            <section>
              <div className="container">
                <div className="row justify-content-center py-4">
                  <div className="col-lg-8 col-md-12 col-sm-10 text-start ">
                    <p className="text-center" dangerouslySetInnerHTML={{ __html: BhagwatVerses?.description }} style={{ marginLeft: "0.0rem", fontFamily: "Lato", color: "#5B5B5B" }}
                    ></p>

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* <div
            className="contyu px-5 pt-4 lato"
            dangerouslySetInnerHTML={{ __html: singlewisdomlist?.content }}
          ></div> */}

        {/* {BhagwatVerses?.price > 1 && (
          <>
            <div className="pb-5 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-lg d-sm-5 fs-4 lato" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                style={{ backgroundColor: "#ff9933", color: "white", width: "200px", borderRadius: "22px" }}
                onClick={handlebuynow}
              >
                Buy
              </button>
            </div>
            <OrderModal orderType={orderType} product={buynow ? BhagwatVerses : ""} />
          </>
        )} */}
      </section>

      {/* text section all end */}

      {/* <div className="mahab-mixer d-none d-lg-block">
      <h1 className="text-center pt-5 galeryhead">
        <span>Related Content</span>
      </h1>
    </div>
    <div className="container artvid pt-5">
      <div className="row g-3 py-5">
       {Array.isArray(fetchedWisdom) && 
        fetchedWisdom?.map((item,index)=>(
          <div className="col-md-6 col-sm-12 col-lg-4 item"
          onClick={()=>handleNavigateToWisdomSubpage(item?.slug)}
          >
          <div className="card item-card card-block">
            <img loading="lazy" src={item?.thumbnail_image} alt="Photo of sunset" />

            <div
              className="d-flex justify-content-end"
              style={{ position: "relative", right: "26px" }}
            >
              <h6 className="pt-0">
              <h6 className="pt-3">
                  {`${
                    item.contentType === "video"
                      ? `Video`
                      : item.contentType === "audio"
                      ? `Audio`
                      : `Article`
                  }`}</h6>
              </h6>
              <i
                    className={`fa-solid ${
                      item.contentType === "video"
                        ? `fa-circle-play`
                        : item.contentType === "audio"
                        ? `fa-headphones`
                        : `fa-book-open`
                    }`}
                    style={{
                      position: "relative",
                      top: "18px",
                      left: "9px",
                    }}
                  ></i>
            </div>
            <div className="container">
            <h4
                    className="mt-3"
                    style={{ fontFamily: "Playfair Display SC",color:"#000000" }}
                  >
                    {item.title.length > maxLength
                      ? `${item.title.substring(0, maxLength)}...`
                      : item.title}
                  </h4>
                  <p
                    className="card-text lato"
                    dangerouslySetInnerHTML={{
                      __html:
                        item.description.length > maxLength
                          ? `${item.description.substring(0, maxLength)}...`
                          : item.description,
                    }}
                    style={{ marginLeft: "0.0rem",fontFamily:"Lato",color:"#5B5B5B"}}
                  ></p>

                   <div className="pb-3 d-flex pt-4">
                    <h4 className="lato" style={{fontSize:"22px"}}>
                      <Moment
                        className="fw-semibold"
                        format="D MMM YYYY"
                        withTitle
                      >
                        {item?.createdAt}
                      </Moment>
                    </h4>
                    <a href="#">
                      {" "}
                      <i
                        className="fa-solid fa-bookmark text-dark"
                        style={{
                          position: "absolute",
                          right: "70px",
                          fontSize: "25px",
                        }}
                      ></i>
                    </a>
                    <a href="#">
                      {" "}
                      <i
                        className="fa-solid fa-share-nodes text-dark"
                        style={{
                          position: "absolute",
                          right: "27px",
                          fontSize: "26px",
                        }}
                      ></i>
                    </a>
                  </div>
            </div>
          </div>
        </div>
        ))}
       
     
      </div>
    </div> */}
    </>
  );
};

export default SingleVerses;
