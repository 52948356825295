import React, { useState, useEffect, useRef, useCallback, useMemo,lazy,Suspense } from "react";
import Instagram from "../../../src/images/Instagramicons.png";
import Whatsapp from "../../../src/images/Whatsappicons.png";
import Twitter from "../../../src/images/twittericons.png";
import Youtube from "../../../src/images/Youtube icons.png";
import facebook1 from "../../../src/images/fbicons.png";
import gnmailicon from "../../../src/images/gmailicons.png";
import phoneicon from "../../../src/images/phone_icon.png";
import drArchikadidiLogo from "../../../src/images/newlogo.png";
import applelogo from "../../../src/images/apple.png";
import andrroidlogo from "../../../src/images/adroidicons.png";
import { getContactUs } from "../../Allslices/ContactUsSlice";
import { useSelector, useDispatch } from "react-redux";
import FixMusicPlayer from "../../maincontent/FixMusicPlayer";
import { Modal } from "react-bootstrap";
import locationicon from "../../../src/images/locationicons.png";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getmyOrder } from "../../Allslices/orderSlice";
import { getAllCourses } from "../../Allslices/courseSlice";
import { getwisdomList } from "../../Allslices/wisdomSlice";
import { createSubcription } from "../../Allslices/subcription";
import { IoCloseSharp, IoLockOpen } from "react-icons/io5";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../appUtils/appToast";
import { getHome } from "../../Allslices/homeSlice";
const Slider =lazy(()=>import("react-slick"));

const Footer = () => {
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.contactUs);
  useEffect(() => {
    dispatch(getContactUs());
  }, [dispatch]);
  const navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const { subscription, isLoading: subscriptionLoading } = useSelector(
    (state) => state.subscription
  );
  const { home } = useSelector((state) => state.home);

  const handleSubscription = useCallback(async (e) => {
    e.preventDefault();
    dispatch(createSubcription({ email })).then((result) => {
      if (result.payload.success === true) {
        handleClose();
        setEmail("");
        SHOW_SUCCESS_NOTIFICATION("Subscribe Succesfully");
      } else {
        SHOW_ERROR_NOTIFICATION("Already Subscribed");
        handleClose();
        setEmail("");
      }
    });
  }, []);

  const { courselist } = useSelector((state) => state.course);
  const { wisdom, limit, currentPage } = useSelector((state) => state.wisdom);
  const [showModal, setShowModal] = useState(false);
  const [showCommunity, setShowCommunity] = useState(false);
  const allcourses = courselist?.all_courses;
  const handleCloseCommunityModal = () => setShowModal(false);
  const handleShowCommunityModal = () => setShowModal(true);

  const handleNavigateToWisdomSubpage = useCallback((slug) => {
    navigate(`/wisdom/${slug}`);
  }, []);
  const handleNavigateToCourse = useCallback((slug) => {
    navigate(`/courses/${slug}`);
    window.location.reload();
  }, []);
  const { orderAll } = useSelector((state) => state?.order);
  const { user, isAuthenticated, isLoading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user?.communityUnlocked) {
      setShowCommunity(true);
    }
  }, [orderAll, dispatch, user]);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (currentPath === "/courses" || currentPath.includes("/courses")) {
      dispatch(getAllCourses({ page: 1 }));
    }
    if (currentPath === "/wisdom" || currentPath.includes("/wisdom")) {
      dispatch(getwisdomList({ page: 1, limit: limit }));
    }

    dispatch(getHome());

    dispatch(getmyOrder());
  }, [currentPath, dispatch]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      // centerMode: true,
      // centerPadding: "20%",
      autoplaySpeed: 1500,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }),
    []
  );
  const sliderSettings1 = useMemo(
    () => ({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      // centerMode: true,
      // centerPadding: "20%",
      autoplaySpeed: 1500,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }),
    []
  );

  let sliderRef1 = useRef();
  let sliderRef2 = useRef();
  const wisdomLists = wisdom;

  const [showBox, setShowBox] = useState(false);

  const handleIconClick = () => {
    setShowBox(!showBox);
  };

  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  }, []);
  const maxLength = 20;
  return (
    <>
      {/* <FixMusicPlayer /> */}
      <footer className="footer-section">
        <div style={{ cursor: "pointer" }}>
          <FixMusicPlayer />
        </div>
        <div className="container">
          <div className="footer-content ">
            <div className="row g-3 mainconta">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-50">
                <div className="d-flex mx-4  footerlistitems gap-0">
                  <ul className="col-md-6 col-lg-6 col-sm-6 px-1">
                    {contact?.pagelink1 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink1}>
                            {contact?.pagename1}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename1}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink1}>
                          {contact?.pagename1}
                        </Link>
                      </li>
                    )}
                    {contact?.pagelink2 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink2}>
                            {contact?.pagename2}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename2}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink2}>
                          {contact?.pagename2}
                        </Link>
                      </li>
                    )}
                    {contact?.pagelink3 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink3}>
                            {contact?.pagename3}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename3}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink3}>
                          {contact?.pagename3}
                        </Link>
                      </li>
                    )}
                    {contact?.pagelink4 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink4}>
                            {contact?.pagename4}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename4}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink4}>
                          {contact?.pagename4}
                        </Link>
                      </li>
                    )}
                    <div className="d-flex flex-column text-align-start my-4">
                      <button
                        className="btn text-start subscribebtn bg-white"
                        onClick={handleShow}
                      >
                        SUBSCRIBE
                      </button>
                    </div>
                  </ul>

                  <ul className="col-md-6 col-lg-6 col-sm-6 borderul px-1">
                    {contact?.pagelink5 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink5}>
                            {contact?.pagename5}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename5}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink5}>
                          {contact?.pagename5}
                        </Link>
                      </li>
                    )}
                    {contact?.pagelink6 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink6}>
                            {contact?.pagename6}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename6}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink6}>
                          {contact?.pagename6}
                        </Link>
                      </li>
                    )}
                    {contact?.pagelink7 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink7}>
                            {contact?.pagename7}
                          </Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename7}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink7}>
                          {contact?.pagename7}
                        </Link>
                      </li>
                    )}

                    {contact?.pagelink8 === "/community" ? (
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink8}>
                            {contact?.pagename8}
                          </Link>
                        </li>
                      ) : (
                        <li
                          onClick={handleShowCommunityModal}
                          className="text-white"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {contact?.pagename8}
                        </li>
                      )
                    ) : (
                      <li>
                        <Link to={contact?.pagelink8}>
                          {contact?.pagename8}
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-50 appsdownloadfooter">
                <h5 className="fw-bolder text-white ">
                  DOWNLOAD <br /> DR. ARCHIKA DIDI APP
                </h5>
                <div className="d-flex imgsandsign">
                  <div className="d-flex flex-column ">
                    <img
                      loading="lazy"
                      src={contact?.footer_image}
                      alt="Dr. Archika Didi"
                      className="didifooterimg"
                    />
                    <img
                      loading="lazy"
                      src={drArchikadidiLogo}
                      alt="Dr. Archika Didi"
                      className="didifooterlogo"
                    />
                  </div>
                  <div className="osbuttons d-flex flex-column mt-3">
                    <a href={home?.appplay} target="_blank">
                      {" "}
                      <button className="d-flex">
                        {" "}
                        <img
                          loading="lazy"
                          src={applelogo}
                          alt="Dr. Archika Didi"
                        />
                        iOS App
                      </button>
                    </a>

                    <a href={home?.googleplay} target="_blank">
                      <button className="d-flex">
                        {" "}
                        <img
                          loading="lazy"
                          src={andrroidlogo}
                          alt="Dr. Archika Didi"
                        />
                        Andriod App
                      </button>
                    </a>
                    <div className="invisible visibility-hidden">bbbbbbbbb</div>
                    <div className="invisible">bbbbbbbbb</div>
                  </div>
                </div>
              </div>
              {currentPath === "/courses" ||
              currentPath.includes("/courses") ? (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-3 footersliderr1 pb-5">
                  <Suspense fallback={<div>Loading...</div>}>
                  <Slider
                    ref={(slider) => (sliderRef1 = slider)}
                    {...sliderSettings}
                  >
                    {Array.isArray(allcourses) &&
                      allcourses.slice(0, 6).map((item, index) => (
                        <div
                          className="card-body bg-white overflow-hidden footersliderr"
                          key={index}
                          onClick={() => handleNavigateToCourse(item?.slug)}
                        >
                          <div className="">
                            <img
                              loading="lazy"
                              src={item?.courseImage}
                              alt="Dr. Archika Didi"
                              className="card-img-top"
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                  </Suspense>
                </div>
              ) : currentPath === "/wisdom" ||
                currentPath.includes("/wisdom") ? (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-3 footercourselider footersliderr1 pb-5 ">
                  <Suspense fallback={<div>Loading...</div>}>

                  <Slider
                    ref={(slider) => (sliderRef2 = slider)}
                    {...sliderSettings1}
                  >
                    {Array.isArray(wisdomLists) &&
                      wisdomLists.map((item, index) => (
                        <div
                          className="card-body bg-white overflow-hidden footersliderr"
                          key={index}
                          onClick={() =>
                            handleNavigateToWisdomSubpage(item?.slug)
                          }
                        >
                          <div className="">
                            <img
                              src={item?.thumbnail_image}
                              alt="Dr. Archika Didi"
                              className="card-img-top"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                  </Suspense>
                </div>
              ) : (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-5 socialscontainer d-flex">
                  <div className="d-flex flex-column contactscontainer pb-4">
                    <h4 className="text-light fw-bold ">CONTACT US</h4>
                    <div className="contacticons d-flex">
                      {contact?.phone ? (
                        <a
                          href={`tel:${contact?.phone}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={contact?.phone}
                        >
                          <img
                            loading="lazy"
                            src={phoneicon}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : null}

                      {contact?.email ? (
                        <a
                          href={`mailto:${contact?.email}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={contact?.email}
                        >
                          <img
                            loading="lazy"
                            src={gnmailicon}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : null}

                      {contact?.address ? (
                        <a
                          href={contact?.address_Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Anand Dham Ashram
Bakkarwala Marg, Nangloi Najafgarh Road
New Delhi 110041"
                        >
                          <img
                            loading="lazy"
                            src={locationicon}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : null}

                      {contact?.whatsapp_Link ? (
                        <a
                          href={contact?.whatsapp_Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={contact?.whatsapp_Link}
                        >
                          <img
                            loading="lazy"
                            src={Whatsapp}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex flex-column followcantainer">
                    <h4 className="text-light fw-bold  ">FOLLOW US</h4>
                    <div className="contacticons d-flex">
                      {contact?.fb_Link ? (
                        <a target="_blank" href={contact?.fb_Link}>
                          <img
                            loading="lazy"
                            src={facebook1}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {contact?.insta_Link ? (
                        <a target="_blank" href={contact?.insta_Link}>
                          <img
                            loading="lazy"
                            src={Instagram}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : (
                        ""
                      )}

                      {contact?.x_Link ? (
                        <a target="_blank" href={contact?.x_Link}>
                          <img
                            loading="lazy"
                            src={Twitter}
                            alt="Dr. Archika Didi"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                        </a>
                      ) : (
                        ""
                      )}

                      {contact?.youtube_Link ? (
                        <a target="_blank" href={contact?.youtube_Link}>
                          <img
                            loading="lazy"
                            src={Youtube}
                            alt="Dr. Archika Didi"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white footorbottom  ">
          <div className="container">
            <div className="row gap-0">
              <div className="col-md-6 col-sm-6 col-6 d-flex align-items-center archiikk">
                <span style={{ fontSize: "1.5em", marginBottom: "-0.07em" }}>
                  &#174;
                </span>{" "}
                Dr. Archika Didi 2024
              </div>
              <div className="col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-end">
                <a className="px-1" href="/terms&conditions">
                  Terms And Conditions
                </a>{" "}
                |{"  "}
                <a className="px-1" href="/privacypolicy">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <h2 className="my-4 text-center mb-5 newslettermodal">
              Subscribe For E-Newsletter
            </h2>
            <form onSubmit={(e) => handleSubscription(e)}>
              <div className="my-3 footeremail">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email address"
                  aria-describedby="emailHelp"
                />
              </div>
              <button type="submit" className="btn btn-primary footersubmitbtn">
                {subscriptionLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    Submit
                    <div
                      className="spinner-border text-light footersubmitloading"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  `Submit`
                )}
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </footer>

      {/* <!-- Modal --> */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="communityModal"
        tabIndex="-1"
        aria-labelledby="communityModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header footercommunitylock">
              <h5 className="modal-title" id="communityModalLabel ">
                Community Locked
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseCommunityModal}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato pb-4 d-flex text-center align-items-center gap-2">
              <IoLockOpen /> Please Buy Something To Unlock The Community !
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
