import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../appUtils/axiosConfig";

const filters = {
  categoryId: "",
  language: "",
  sort: "",
  contentType: "",
  text: "",
};
const initialState = {
  isLoading: false,
  error: null,
  podcastlist: null,
  currentPage: 0,
  limit: 8,
  totalalbums: 0,
  podcastCategoryList: [],
  podcast: null,
  podcastbanner: null,
  Languages: null,
  Filters: filters,
};

export const getAllPodcast = createAsyncThunk(
  "podcast/allpodcast",
  async ({ page, tags, limit }, { dispatch, getState, rejectWithValue }) => {
    const {
      podcast: {
        Filters: { categoryId, language, sort, contentType, text },
      },
    } = getState();

    try {
      dispatch(getallpodcastStart());
      const response = await axios.get(
        `podcast/all?page=${page ?? +1}&limit=${limit ?? ""}&categoryId=${
          categoryId ?? ""
        }&tags=${tags ?? ""}&sort=${sort ?? ""}&language=${
          language ?? ""
        }&text=${text ?? ""}`
      );
      dispatch(getallpodcastSuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(getallpodcastfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getSinglePodcast = createAsyncThunk(
  "podcast/singlepodcast",
  async (slug, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getsinglepodcastStart());
      const response = await axios.get(`podcast/${slug}`);
      dispatch(getsinglepodcastSuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(getsinglepodcastfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getPodcastBanner = createAsyncThunk(
  "config/get_podcastbanner",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getpodcastbannerStart());
      const response = await axios.get(`config/get_podcastbanner`);
      dispatch(getpodcastbannerSuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(getpodcastbannerfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getPodcastCategory = createAsyncThunk(
  "podcast/getPodcastCategory",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(getallpodcastCategoryStart());
      const response = await axios.get(`podcast/podcastcategory_all`);
      dispatch(
        getallpodcastCategorySuccess(response?.data?.allpodcastcategories)
      );
      return response?.data?.allpodcastcategories;
    } catch (error) {
      dispatch(getallpodcastCategoryfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLanguages = createAsyncThunk(
  "language/get_languages",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(languagesStart());
      const response = await axios.get(`languages/get_languages`);
      dispatch(languagesSuccess(response?.data?.settingItems));
    } catch (error) {
      dispatch(languagesFailure(error.response.data.message));
    }
  }
);

const podcastSlice = createSlice({
  name: "podcast",
  initialState,
  reducers: {
    getallpodcastStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getallpodcastSuccess(state, action) {
      state.isLoading = false;

      state.podcastlist = action.payload?.albums;
      state.limit = action.payload.limit;
      state.currentPage = action.payload.currentPage;
      state.limit = action.payload.limit;
      state.totalalbums = action.payload.totalalbums;
    },
    getallpodcastfailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getsinglepodcastStart(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    getsinglepodcastSuccess(state, action) {
      state.isLoading = false;
      state.podcast = action.payload?.podcast;
    },
    getsinglepodcastfailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getpodcastbannerStart(state, action) {
      state.isLoading = true;
      state.error = false;
    },
    getpodcastbannerSuccess(state, action) {
      state.isLoading = false;
      state.podcastbanner = action.payload;
    },
    getpodcastbannerfailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getallpodcastCategoryStart(state) {
      state.isLoading = true;
      state.error = null;
      state.podcastCategoryList = [];
    },
    getallpodcastCategorySuccess(state, action) {
      state.isLoading = false;
      state.podcastCategoryList = action.payload;
    },
    getallpodcastCategoryfailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    languagesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    languagesSuccess(state, action) {
      state.Languages = action.payload;
      state.isLoading = false;
    },
    languagesFailure(state, action) {
      state.Languages = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    filtersUpdate(state, action) {
      const { categoryId, language, sort, contentType, text } = action.payload;
      if (categoryId !== undefined) {
        state.Filters.categoryId = categoryId;
      }
      if (language !== undefined) {
        state.Filters.language = language;
      }
      if (sort !== undefined) {
        state.Filters.sort = sort;
      }
      if (contentType !== undefined) {
        state.Filters.contentType = contentType;
      }
      if (text !== undefined) {
        state.Filters.text = text;
      }
    },
  },
});

export const {
  getallpodcastStart,
  getallpodcastSuccess,
  filtersUpdate,
  getallpodcastfailure,
  getsinglepodcastStart,
  getsinglepodcastSuccess,
  getsinglepodcastfailure,
  getpodcastbannerStart,
  getpodcastbannerSuccess,
  getpodcastbannerfailure,
  getallpodcastCategoryStart,
  getallpodcastCategorySuccess,
  getallpodcastCategoryfailure,
  languagesStart,
  languagesSuccess,
  languagesFailure,
} = podcastSlice.actions;

export default podcastSlice.reducer;
