import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import { BsSoundwave } from "react-icons/bs";
import { FaCircleStop } from "react-icons/fa6";
import { FaRegCircleStop } from "react-icons/fa6";
import {
  nextTrack,
  prevTrack,
  setPlaying,
  setShowMusicBar,
} from "../Allslices/playerSlice";
import { convertToMins } from "../appUtils";
import Playcontrols from "./Playcontrols";
import VolumeControl from "./VolumeControl";
import rect259 from "../images/Rectangle 259.png";
import ".././component/layout/style.css";

const FixMusicPlayer = ({
  audioRef,
  songDetails,
  setSongDetails,
  albumId,
  id,
}) => {
  const dispatch = useDispatch();
  const {
    currentTrack,
    isPlaying,
    musicBar,
    trackList,
    repeatStatus,
    currentIndex,
  } = useSelector((state) => state.player);
  const { artiste } = useSelector((state) => state.album);
  const currentPath = window.location.pathname;
  const isEndOfTracklist = currentIndex === trackList?.length - 1;

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      dispatch(setPlaying(false));
      dispatch(setShowMusicBar(true));
    } else {
      audioRef.current.play();
      dispatch(setPlaying(true));
      dispatch(setShowMusicBar(true));
    }
  };

  const handleclosebutton = () => {
    audioRef.current.pause();
    dispatch(setPlaying(false));
    dispatch(setShowMusicBar(false));
  };

  const handleNextSong = () => {
    if (trackList.length === 1) {
      restartSong();
    } else {
      dispatch(nextTrack());
    }
  };

  const handlePreviousSong = () => {
    if (trackList.length === 1) {
      restartSong();
    } else {
      dispatch(prevTrack());
    }
  };

  const restartSong = () => {
    setSongDetails((prev) => ({ ...prev, time: 0 }));
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;
    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };

  const handleEnded = () => {
    switch (repeatStatus) {
      case "OFF":
        if (!isEndOfTracklist) handleNextSong();
        break;
      case "TRACKLIST":
        handleNextSong();
        break;
      case "SINGLE":
        audioRef.current.play();
        break;
      default:
        break;
    }
  };

  const changeVolume = (value) => {
    const newVolume = value / 100;
    if (!isNaN(newVolume) && isFinite(newVolume)) {
      setSongDetails((prevValues) => ({ ...prevValues, volume: newVolume }));
      audioRef.current.volume = newVolume;
    }
  };

  const volumeToggle = () => {
    if (songDetails?.volume > 0) {
      setSongDetails((prev) => ({ ...prev, volume: 0 }));
      audioRef.current.volume = 0;
    } else {
      setSongDetails((prev) => ({ ...prev, volume: 1 }));
      audioRef.current.volume = 1;
    }
  };

  return (
    <>
      {musicBar && (
        <div
          style={{ maxWidth: "100%" }}
          className={`music2 py-1 py-lg-3 py-md-2 container ${
            currentPath.includes("/music/") ||
            currentPath.includes("/podcast/") ||
            currentPath.includes("/wellness/") ||
            currentPath.includes("/wisdom/")
              ? "invisible"
              : "visible"
          }`}
        >
          <div className="container">
            <div className="row d-flex align-items-center">
              <div
                className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center gap-4 gap-sm-1 gap-md-1 gap-lg-2"
                style={{ justifyContent: "space-evenly" }}
              >
                <div className="">
                  <img
                    src={
                      currentTrack?.thumbnail_image
                        ? currentTrack?.thumbnail_image
                        : rect259
                    }
                    style={{
                      borderRadius: "50%",
                      height: "45px",
                      width: "45px",
                    }}
                    alt="Dr. Archika Didi"
                  />
                </div>
                <div className="text-center">
                  <h5 className="mb-0">{currentTrack?.title}</h5>
                  <a href="#" className="d-none">
                    {currentTrack?.artist?.map((item) => item.title).join(", ")}
                  </a>
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-2 text-center d-none d-lg-block">
                {currentTrack && isPlaying && (
                  <iframe
                    src="https://giphy.com/embed/H4Ke1ghWdhic0HLZ6L"
                    width="50"
                    height="30"
                    frameBorder="0"
                    className="giphy-embed"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text
                    fontSize="xs"
                    color="zinc.500"
                    style={{ marginBottom: "0px", padding: "4px" }}
                  >
                    {audioRef?.current
                      ? convertToMins(audioRef.current.currentTime)
                      : "0:00"}
                  </Text>
                  <Slider
                    outline={0}
                    _focus={{ outline: 0 }}
                    aria-label="seek-slider"
                    defaultValue={0}
                    width="17rem"
                    onChange={seekPoint}
                    value={!isNaN(songDetails?.time) ? songDetails?.time : 0}
                  >
                    <SliderTrack bg="gray.400">
                      <SliderFilledTrack bg="#b767a2" />
                    </SliderTrack>
                    <SliderThumb boxSize={6} outline={0}>
                      <Box color="#b767a2" as={BsSoundwave} />
                    </SliderThumb>
                  </Slider>
                  <Text
                    fontSize="xs"
                    color="zinc.500"
                    style={{ marginBottom: "0px", padding: "4px" }}
                  >
                    {audioRef?.current
                      ? convertToMins(audioRef.current.duration)
                      : "0:00"}
                  </Text>
                </Flex>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-6 d-flex align-items-center justify-content-sm-around gap-1"
                style={{ justifyContent: "space-around" }}
              >
                <div>
                  <Playcontrols
                    isPlaying={isPlaying}
                    onNext={handleNextSong}
                    onPlay={handlePlayPause}
                    onPrevious={handlePreviousSong}
                    repeatStatus={repeatStatus}
                  />
                </div>
                <div className="text-center d-none d-lg-block">
                  <VolumeControl
                    onChange={(value) => changeVolume(value)}
                    onToggle={volumeToggle}
                    volume={songDetails ? songDetails?.volume : 0}
                  />
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btn mt-1"
                    onClick={() => handleclosebutton(false)}
                    title="STOP AUDIO"
                  >
                    <FaRegCircleStop size={25} color="#b767a2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
    </>
  );
};

export default FixMusicPlayer;
