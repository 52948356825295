import React from 'react';
import PropTypes from 'prop-types';

const EventBlogCards = React.memo(({ blog, onClick }) => {
  const { title, slug, thumbnail_image, description } = blog;

  const handleClick = () => {
    onClick(slug);
  };

  return (
    <div
      className="col-md-12 col-sm-12 col-lg-12 pb-3"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <div
        className="card item-card text-dark mx-2 card_height1"
        style={{ borderRadius: '10px' }}
      >
        <img
          src={thumbnail_image}
          alt="Blog Thumbnail"
          style={{
            borderRadius: '10px 10px 0px 0px',
            aspectRatio: '3/2',
          }}
        />
        <div className="container">
          <h5
            className="mt-1 pt-1 fw-bold text-capitalize"
            style={{
              fontFamily: 'lato',
              color: 'black',
              height: '55px',
            }}
          >
            {title.length > 40 ? `${title.substring(0, 40)}...` : title}
          </h5>
          <p
            className="card-text lato text-capitalize pb-3"
            dangerouslySetInnerHTML={{
              __html:
                description.length > 180
                  ? `${description.substring(0, 180)}...`
                  : description,
            }}
            style={{
              fontFamily: 'Lato',
              color: '#5B5B5B',
            }}
          ></p>
        </div>
      </div>
    </div>
  );
});

EventBlogCards.propTypes = {
  blog: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EventBlogCards;
