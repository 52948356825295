import React, { useEffect, useState } from "react";
import MetaData from "../component/layout/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMeditation } from "../Allslices/meditationSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import Gallery from "react-photo-gallery";
import { Modal, ModalGateway } from "react-images";
import { getHome } from "../Allslices/homeSlice";
import ExploreBg from "../../src/images/explorebg.png";

function MeditationSinglePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { meditation_single_retreats } = useSelector(
    (state) => state.meditation_page
  );
 

  const { slug } = useParams();
  const location = useLocation();

  const default_image = location.state?.default_image;
  useEffect(() => {
    dispatch(getSingleMeditation(slug));
    dispatch(getHome());
  }, []);

  const [displayedIndex, setDisplayedIndex] = useState({ start: 2, end: 5 });
  const [currentPage, setCurrentPage] = useState(4);

  const handleLoadMore = () => {
    const newStart = displayedIndex.end + 1;
    const newEnd = displayedIndex.end + 4; // Adjusted to add 5 more images
    setDisplayedIndex({ start: newStart, end: newEnd });
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    const newStart = Math.max(displayedIndex.start - 4, 1);
    const newEnd = newStart + 3;
    setDisplayedIndex({ start: newStart, end: newEnd });
    setCurrentPage(currentPage - 1);
  };

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isFadeIn = window.scrollY > 60;
      setFadeIn(isFadeIn);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  

  const [selectedImage, setSelectedImage] = useState("");
  const [largeImage, setLargeImage] = useState("");
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (
      meditation_single_retreats?.meditationGalleryList &&
      meditation_single_retreats?.meditationGalleryList.length > 0
    ) {
      setLargeImage(
        meditation_single_retreats?.meditationGalleryList[0]?.thumbnail_image
      );
    }
  }, [meditation_single_retreats]);

  

  const smallImages =
    meditation_single_retreats?.meditationGalleryList?.slice(
      displayedIndex.start - 1,
      displayedIndex.end
    ) || [];

  

  const handleHoverThumbnail = (url) => {
   
    setLargeImage(url);
  };

  const openGalleryModal = (image, index) => {
   
    setSelectedImageIndex(index);
    setSelectedImage(
      meditation_single_retreats?.meditationGalleryList[selectedImageIndex]
        ?.thumbnail_image
    );
    setIsGalleryModalOpen(true);
  };

  const closeGalleryModal = () => {
    setSelectedImage(null);
    setIsGalleryModalOpen(false);
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => prevIndex - 1);
  
    setSelectedImage(
      meditation_single_retreats?.meditationGalleryList[selectedImageIndex - 1]
        ?.thumbnail_image
    );
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => prevIndex + 1);
    
    setSelectedImage(
      meditation_single_retreats?.meditationGalleryList[selectedImageIndex + 1]
        ?.thumbnail_image
    );
  };

 

  let splitUrl;

  if (meditation_single_retreats?.page_banner_image) {
    splitUrl = meditation_single_retreats?.page_banner_image.split(
      "http://api.drarchikadidi.com/"
    );
   
  }
  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? default_image
        : meditation_single_retreats?.page_banner_image;
  }


  const [isZoomed, setIsZoomed] = useState(false);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

 
  return (
    <>
      <MetaData
        title={meditation_single_retreats?.meta_title}
        tags={meditation_single_retreats?.meta_tag}
        description={meditation_single_retreats?.meta_description}
      />
      <div className="">
        <div
          className="blogimg evenetbgb"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(75, 100, 100, 0.8), rgba(4, 4, 4, 0.48)) , url('${meditation_single_retreats?.thumbnail_image}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "490px!important",
          }}
        >
          <div className="container mpt" style={{ paddingTop: "200px" }}>
            <div className="row ">
              <div className="col-lg-12 col-sm-12 d-flex align-items-center justify-content-center ">
                <h1
                  className=""
                  style={{
                    color: "rgba(255, 255, 255, 1)",
                    fontWeight: 400,
                    fontFamily: "myFont",
                    fontSize: "60px",
                  }}
                >
                  {meditation_single_retreats?.title}
                </h1>
              </div>
              <div className="col-lg-12 col-sm-12 d-flex align-items-center justify-content-center  lh-lg ">
                <p
                  className={"text-center mt-4"}
                  style={{
                    fontSize: "20px",
                    color: "white",
                   
                  }}
                >
                  {meditation_single_retreats?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div
            style={{
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(245, 182, 229, 0.2) 100%)`,
              backgroundSize: "cover",
            }}
          >
            <div className="container py-4">
              <div className=" text-center lh-lg ">
                <p className="pt-3 text-dark fw-bold fs-5 subchekmed">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: meditation_single_retreats?.Longdescription,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center singleevntsres">
          <h1 className="py-3" style={{ fontFamily: "Playfair" }}>
            Gallery
          </h1>
        </div>
        <div className="container my-6">
          <section className="row position-relative px-3 flex-directori">
            <div className="col-lg-6 col-sm-6 col-12">
              <div
                className="large-image-container"
                style={{ borderRadius: "8px" }}
              >
                <Gallery
                  photos={[{ src: largeImage, width: 500, height: 650 }]}
                  onClick={() => openGalleryModal(largeImage, 0)}
                />
                <ModalGateway>
                  {isGalleryModalOpen && (
                    <Modal onClose={closeGalleryModal}> 
                      {/* Modal content */}
                      <div className="click-zoom" style={{ position: 'relative' }}>
      <img
        className={`galleryResponsvieModalImage ${isZoomed ? 'zoomed' : 'zoomin'}`}
        src={meditation_single_retreats?.meditationGalleryList[selectedImageIndex]?.thumbnail_image}
        alt="Dr. Archika Didi"
        style={{
          width: 750,
          borderRadius: '16px',
          aspectRatio: '16/9',
          cursor: isZoomed ? 'zoom-out' : 'zoom-in',
        }}
        onClick={toggleZoom}
      />
      <span className="zoom-in" onClick={() => setIsZoomed(true)}>
        <i className="fa-solid fa-magnifying-glass-plus"></i>
      </span>
      <span className="zoom-out" onClick={() => setIsZoomed(false)}>
        <i className="fa-solid fa-magnifying-glass-minus"></i>
      </span>
      <span className="close-button" onClick={closeGalleryModal}>
        <i className="fa-solid fa-circle-xmark"></i>
      </span>
      {selectedImageIndex > 0 && (
        <button className="prev-button" onClick={handlePrev} disabled={selectedImageIndex === 0}>
          <i
            className="fa-solid fa-chevron-left"
            style={{
              position: 'absolute',
              top: '46%',
              left: '10px',
              fontSize: '18px',
              color: 'white',
            }}
          ></i>
        </button>
      )}
      {selectedImageIndex < meditation_single_retreats?.meditationGalleryList.length - 1 && (
        <button
          className="next-button"
          onClick={handleNext}
          disabled={selectedImageIndex === meditation_single_retreats?.meditationGalleryList.length - 1}
        >
          <i
            className="fa-solid fa-chevron-right"
            style={{
              position: 'absolute',
              top: '46%',
              right: '10px',
              fontSize: '18px',
              color: 'white',
            }}
          ></i>
        </button>
      )}
    </div>
                    </Modal>
                  )}
                </ModalGateway>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="row ">
                {smallImages.map((item, index) => (
                  <div key={index} className="col-lg-6 col-sm-6 col-12">
                    <div
                      className="gallery-wrapper my-2"
                      onClick={() =>
                        openGalleryModal(item?.thumbnail_image, index)
                      }
                      onMouseEnter={() =>
                        handleHoverThumbnail(item?.thumbnail_image, index)
                      }
                    >
                      <img
                        src={item?.thumbnail_image}
                        alt="Dr. Archika Didi"
                        className="gallery-image"
                        style={{
                          overflow: "hidden",
                          cursor: "pointer",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-3 ">
                {currentPage > 4 && (
                  <button className=" me-2">
                    <i
                      className="fa-solid fa-circle-chevron-left text-secondary left-gallery-icon"
                      onClick={handlePrevious}
                    ></i>
                  </button>
                )}
                {meditation_single_retreats?.meditationGalleryList.length >
                  displayedIndex.end && (
                  <button className=" ">
                    <i
                      className="fa-solid fa-circle-chevron-right text-secondary right-gallery-icon"
                      onClick={handleLoadMore}
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <div className="container mt-3 mb-3">
          <div className="row">
            <div
              className="col-md-12 text-center explore-bg-image"
              style={{
                backgroundImage: `url(${ExploreBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                // padding: "10rem",
              }}
            >
              <h4 className="text-dark display-5 haedingfont" style={{fontFamily:'myFont'}}>
                Explore Our Upcoming Events
              </h4>
              <p className="text-dark fs-4" style={{fontFamily:"lato"}}>And stay connected with us</p>
              <button
                className="btn text-white shadowbuttons btn-lg mb-3"
                onClick={() => navigate("/events")}
              >
                Explore Events
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MeditationSinglePage;
