import React, { useEffect } from "react";
import MetaData from "../component/layout/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { getMeditation, getAllMeditation } from "../Allslices/meditationSlice";
import { useNavigate } from "react-router";
import SmallLoader from "./SmallLoader";

export default function Meditation() {
  const dispatch = useDispatch();
  const { meditation_config, meditation_retreats } = useSelector(
    (state) => state.meditation_page
  );

  useEffect(() => {
    dispatch(getMeditation());
    dispatch(getAllMeditation());
  }, []);

  let navigate = useNavigate();

  const handleClick = (slug) => {
    navigate(`/meditation/SinglePage/${slug}`, {
      state: { default_image: meditation_config?.default_image },
    });
  };

  return (
    <>
      <MetaData
        title={meditation_config?.meta_title}
        description={meditation_config?.meta_description}
        tags={meditation_config?.meta_tag}
      />
      <div>
        {meditation_config ? (
          <>
            <section className="overflow-hidden">
              <div className="desktop-only">
                <div className="position-relative d-flex">
                  <img
                    loading="lazy"
                    src={`${meditation_config?.background_image}`}
                    alt="Dr. Archika Didi"
                    className="w-100"
                  />
                  <div className="position-absolute h-100 w-100 top-0">
                    <div className="container h-100">
                      <div className="row h-100 align-items-center">
                        {meditation_config?.alignItem === "left" && (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                              <div>
                                <h1 className="fw-bold mt-5">
                                  <span className="wisdomhead haedingfont">
                                    {meditation_config?.heading}
                                  </span>
                                </h1>
                                <p
                                  className="pt-4  text-dark fw-bold fs-5 meditationmchek "
                                  style={{
                                    textAlign: "justify",
                                    fontFamily: "Lato",
                                  }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: meditation_config?.subheading,
                                    }}
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                          </>
                        )}
                        {meditation_config?.alignItem === "right" && (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>

                            <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                              <div>
                                <h1 className="fw-bold mt-5 ">
                                  <span className="wisdomhead haedingfont">
                                    {meditation_config?.heading}
                                  </span>
                                </h1>
                                <p
                                  className="pt-4  text-dark fw-bold fs-5 meditationmchek "
                                  style={{
                                    textAlign: "justify",
                                    fontFamily: "Lato",
                                  }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: meditation_config?.subheading,
                                    }}
                                  />
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-only">
                <div
                  className="container-fluid mobile-only"
                  style={{ position: "relative", display: "flex" }}
                >
                  <img
                    loading="lazy"
                    src={`${meditation_config?.res_background_image}`}
                    alt="Dr. Archika Didi"
                  />
                  <div
                    className="container"
                    style={{ position: "absolute", height: "100%" }}
                  >
                    <div className="row" style={{ height: "100%" }}>
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        {meditation_config?.alignItem === "left" && (
                          <>
                            <p
                              className="homebannertitle"
                              style={{ fontFamily: "myFont", fontSize: "24px" }}
                              dangerouslySetInnerHTML={{
                                __html: meditation_config?.res_heading,
                              }}
                            ></p>
                          </>
                        )}
                      </div>
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        {meditation_config?.alignItem === "right" && (
                          <>
                            <p
                              className="homebannertitle"
                              style={{ fontFamily: "myFont", fontSize: "24px" }}
                              dangerouslySetInnerHTML={{
                                __html: meditation_config?.res_heading,
                              }}
                            ></p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <SmallLoader />
        )}

        {meditation_retreats ? (
          <section>
            <div
              className=""
              style={{
                backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(156, 144, 216, 0.2) 100%)`,
                backgroundSize: "cover",
              }}
            >
              <div className="container d-lg-none d-md-block d-sm-block ">
                <div className=" text-center lh-lg ">
                  <p
                    className="pt-3  text-dark fw-bold fs-5 meditationmchek p-2"
                    style={{ textAlign: "justify", fontFamily: "Lato" }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: meditation_config?.subheading,
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center py-5 singleevntsres">
              <h1
                style={{
                  fontWeight: "bold",
                  fontFamily: "myFont",
                }}
              >
                Meditation Retreats
              </h1>

              <div className="py-1 mainmedicont">
                {Array.isArray(meditation_retreats) &&
                  meditation_retreats
                    .filter((item) => item?.status == true)
                    .map((item, index) => (
                      <div
                        onClick={() => handleClick(item?.slug)}
                        className="card item-card container my-4 meditaii11"
                        style={{
                          backgroundImage: `url(${item?.thumbnail_image})`,
                          backgroundSize: "cover",
                        }}
                        key={index}
                      >
                        <div className="card-img-overlay d-flex align-items-center justify-content-center p-0 ">
                          <h5 className="card-title">
                            <span>{item?.title}</span>
                          </h5>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </section>
        ) : (
          <SmallLoader />
        )}
      </div>
    </>
  );
}
