import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../component/layout/style.css";
import menicon from "../../../src/images/menicon.jpeg";
import Moment from "react-moment";
import { SHOW_ERROR_NOTIFICATION } from "../../appUtils/appToast";
import {
  addComment,
  fetchPosts,
  isAnonymusComment,
  isAnonymusPost,
  likePost,
} from "../../Allslices/communitySlice";
import { allComments } from "../../Allslices/communitySlice";
import AppCarousel from "../../component/shared/AppCarousel";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import CommentItem from "./CommentItem";
import AddFileModal from "../../component/modal/AddFileModal";
import { loadUser } from "../../component/auth/authSlice";
import anonymousUser from "../../images/anonymous_user.png";

const PostItem = ({ listItem, caItem }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { _id: postId, commentPage } = listItem;

  const {
    likePostState: { loading, loadingId },
    allCommentsState: { loading: caLoading, loadingId: caId },
    commentAnonymus,
  } = useSelector((state) => state.community);
  const postType = listItem?.type;

  let commentsOrAnswers =
    postType == "question" ? listItem?.answers : listItem?.comments;

  const totalLikes = listItem?.likedBy?.length ?? 0;

  const userImage = localStorage.getItem("userImage");

  const callLikePost = useCallback(() => {
    dispatch(likePost(postId)).then((resultAction) => {
      if (resultAction?.error) {
        SHOW_ERROR_NOTIFICATION(resultAction.payload);
        return;
      }
    });
  }, [postId]);

  const loadMoreComments = useCallback(() => {
    dispatch(
      allComments({ postId: postId, page: commentPage ?? 1, postType })
    ).then((resultAction) => {
      if (resultAction?.error) {
        SHOW_ERROR_NOTIFICATION(resultAction.payload);
        return;
      }
    });
  }, [postId, commentPage]);

  // region addComment
  const [selectedFiles, setSelectedFiles] = useState({
    audio: null,
    video: null,
    image: [],
  });

  const initialValue = {
    description: "",
    image: null,
    audio: null,
    video: null,
    anonymous: false,
  };

  const [showAddFile, setShowAddFile] = useState(false);

  const [formData, setFormData] = useState(initialValue);

  const callAddComment = () => {
    formData.image = selectedFiles.image;
    formData.audio = selectedFiles.audio;
    formData.video = selectedFiles.video;
    // formData.anonymous = commentAnonymus;

    dispatch(addComment({ postId, inputData: formData, postType })).then(
      (resultAction) => {
        if (resultAction?.error) {
          SHOW_ERROR_NOTIFICATION(resultAction.payload);
          return;
        }
        setFormData(initialValue);
        dispatch(fetchPosts({ type: "all" }));
      }
    );

    dispatch(isAnonymusComment(false));
  };

  const handleInputChange = (e) => {
    const { name, type, checked, value, files } = e.target;

    if (type === "checkbox") {
      // Update formData for checkbox inputs
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      // Handle other input types (text, file, etc.)
      setFormData({
        ...formData,
        [name]: type === "file" ? files : value,
      });
    }
  };

  let isFileAttached =
    selectedFiles?.audio !== null ||
    selectedFiles?.video !== null ||
    selectedFiles?.image.length > 0;
  // #endregion

  return (
    <div
      className="border mt-5 con_box overflow-y-auto overflow-x-hidden"
      style={{ height: "auto", maxHeight: "100vh" }}
    >
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="commeimg d-flex p-3">
          {listItem.anonymous ? (
            <>
              <i
                className="fa-regular fa-circle-user p-1"
                style={{ fontSize: "3rem" }}
              ></i>
              <div className="mx-3">
                <h6 className="fw-bold mb-0">Anonymous User</h6>
                <Moment className="text-secondary" format="lll" withTitle>
                  {listItem?.createdAt}
                </Moment>
              </div>
            </>
          ) : (
            <>
              <img
                loading="lazy"
                src={listItem?.createdBy?.imageUrl ?? ""}
                alt="Dr. Archika Didi"
                style={{ height: "48px", width: "48px", borderRadius: "50%" }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "default_usericon.jpg";
                  // currentTarget.style.width = "100px";
                }}
              />
              <div className="mx-3">
                <h5 className="fw-bold">{listItem?.createdBy?.name}</h5>
                <Moment className="text-secondary" format="lll" withTitle>
                  {listItem?.createdAt}
                </Moment>
              </div>
            </>
          )}

          <div style={{ flexGrow: "1" }}></div>
        </div>

        <div className="py-3 px-3">
          <p>{listItem?.description}</p>
        </div>
      </div>

      <div>
        {listItem?.fileUrls?.images?.length > 0 && (
          <AppCarousel
            id={listItem._id}
            images={listItem?.fileUrls?.images.map((item, index) => item.url)}
          />
        )}
        {/* </div> */}

        {listItem?.fileUrls?.audio && (
          <div className="d-flex justify-content-center py-5">
            <audio
              controlsList="nodownload"
              controls
              src={listItem?.fileUrls?.audio}
            />
          </div>
        )}

        {listItem?.fileUrls?.video && (
          <MediaPlayer
            title={`Video by ${listItem?.createdBy?.name}`}
            src={listItem?.fileUrls?.video}
          >
            <MediaProvider />
            <DefaultVideoLayout icons={defaultLayoutIcons} />
          </MediaPlayer>
        )}
      </div>
      {/* <hr /> */}
      <div className="py-2 px-4">
        <div className="likeimg d-flex align-items-center">
          <div className="p-2 d-flex justify-content-center align-items-center">
            {loadingId == listItem?._id && loading ? (
              <div className="app-circle-lg">
                <div className="spinner-border text-dark" role="status"></div>
              </div>
            ) : (
              <button
                className={` ${listItem?.liked ? "btn-danger" : "btn-light"}`}
                onClick={callLikePost}
              >
                <i
                  className={`fa-solid fa-heart commneticone ${
                    listItem?.liked ? "text-danger" : "text-dark"
                  }`}
                ></i>
              </button>
            )}
          </div>

          <div className="mx-2 d-flex gap-1 d-flex justify-content-end align-items-end">
            {listItem?.lastLikedBy && (
              <h6 className="fw-bold m-0">{listItem?.lastLikedBy}</h6>
            )}
            <h6
              className="text-secoundry m-0"
              style={{ fontSize: "8px!important" }}
            >{`${totalLikes > 1 ? `and ${totalLikes - 1} more ` : ""}${
              totalLikes > 0 ? "liked this" : ""
            }`}</h6>
          </div>
        </div>
      </div>

      <div
        className="bgcolorc p-1"
        style={{
          borderBottomLeftRadius: `${
            commentsOrAnswers?.length > 0 ? "0px" : "15px"
          }`,
          borderBottomRightRadius: `${
            commentsOrAnswers?.length > 0 ? "0px" : "15px"
          }`,
        }}
      >
        <div className="row d-flex align-items-center justify-content-evenly px-3">
          <div className="d-flex col-lg-5 col-md-6 col-sm-12 gap-2 p-1 justify-content-between align-items-center">
            <div style={{ borderRadius: "50%", width: "20%" }}>
              {!formData.anonymous ? (
                <img
                  loading="lazy"
                  src={user?.image ?? ""}
                  alt="Dr. Archika Didi"
                  style={{ borderRadius: "50%", height: "48px" }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "default_usericon.jpg";
                  }}
                />
              ) : (
                <img
                  loading="lazy"
                  src={anonymousUser ?? ""}
                  alt="Dr. Archika Didi"
                  style={{ borderRadius: "50%", height: "48px" }}
                />
              )}
            </div>
            <div style={{ width: "80%" }}>
              <input
                type="text"
                name="description"
                style={{
                  minWidth: "100%",
                  padding: "10px",
                  outline: "none",
                  borderRadius: "20px",
                }}
                value={formData.description}
                onChange={handleInputChange}
                className="border-0"
                placeholder={`Add ${
                  postType == "question" ? "an Answer" : "a Comment"
                }.......`}
              ></input>
            </div>
          </div>

          <div className="d-flex col-lg-4 col-sm-12 col-md-6 gap-2 p-1 align-items-center justify-content-center">
            <div
              className="form-check text-center d-flex gap-3"
              id="anonymousPadding"
            >
              <div>
                <input
                  className="form-check-input ms-lg-2 ms-md-2"
                  type="checkbox"
                  checked={formData.anonymous}
                  id="flexCheckDefault"
                  name="anonymous"
                  onChange={handleInputChange}
                />
                <label
                  className="form-check-label px-2"
                  htmlFor=""
                  id="anonymousFont"
                  style={{ fontSize: "18px" }}
                >
                  Post Anonymous
                </label>
              </div>
            </div>

            <div className=" text-center ">
              <button onClick={() => setShowAddFile(true)}>
                <span>
                  <i
                    className={`fa-solid fa-paperclip ${
                      isFileAttached ? "text-success" : "text-secondary"
                    } fs-3`}
                  />
                </span>
              </button>
            </div>
          </div>

          <div className="col-lg-3 col-sm-12 col-md-12 text-center p-1">
            {listItem?.addCommentLoading ? (
              <div
                className="spinner-border align-self-center ms-3"
                role="status"
              ></div>
            ) : (
              <button
                className="btn btn-lg text-light align-self-center "
                onClick={callAddComment}
                style={{
                  backgroundColor: "#B767A2",
                  borderRadius: "80px",
                  fontSize: "1rem",
                }}
              >{`Add ${
                postType == "question" ? "an Answer" : "a Comment"
              }`}</button>
            )}
          </div>
        </div>
      </div>

      {commentsOrAnswers?.map((caItem, index) => (
        <CommentItem
          key={caItem?._id}
          caItem={caItem}
          postId={postId}
          postType={postType}
        />
      ))}

      {commentsOrAnswers?.length > 0 && !listItem?.hideShowMore && (
        <div className="row">
          <div className="col-12 p-2">
            {caId == listItem?._id && caLoading ? (
              <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <button
                  className="btn border fs-6 combtn rounded-pill"
                  onClick={loadMoreComments}
                >
                  View more{" "}
                  {`${postType == "question" ? "Answers" : "Comments"}`}&nbsp;
                  <i className="fa-solid fa-angle-down"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {/* <!-- Modal --> */}
      {showAddFile && (
        <AddFileModal
          open={showAddFile}
          onHide={() => setShowAddFile(false)}
          inputFiles={selectedFiles}
          onSubmit={(result) => setSelectedFiles(result)}
          longerMedia={postType == "question"}
        />
      )}
    </div>
  );
};

export default PostItem;
