import React from "react";
import img3 from "../images/image 3.png";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
import playbtn from "../images/playy-button (1).png";

const WellnessVideo = () => {
  const url = window.location.href;

  return (
    <>
      {/* banner section */}
      <div className="rect572bg blogimg mb-2">
        <div className="container blogsub ">
          <div className="row text-center p-5">
            <div className="col-lg-12 col-sm-12">
              <h1 className=" text-dark text-uppercase">
                Radhe Krishna Story - The First Meeting of Radhe and Krishna
              </h1>
              <p
                className="pt-3 text-dark fs-6 fw-semibold"
                style={{ lineHeight: "2" }}
              >
                The story of Mahabharat is rife with curses and boons. Ever
                wondered how much energy it takes to curse someone? Is it easier
                to bless than to curse? Why does the potency of a curse vary, in
                traditional terms? Discover the answers to all these questions
                with Dr Archika Didi.
              </p>
             
            </div>
          </div>
        </div>
        <div className="imagesee pt-5 mt-5">
          <img loading="lazy" src={img3} alt="Dr. Archika Didi" className="" />
        </div>
      </div>
      {/* banner section end */}
      {/* text section all */}
      <section>
        <div className="container pt-3">
          <div className="textrt pt-5 mt-5">
            <div className="row px-5 icotext text-center">
              <div className="col-lg-6">
                <p>Video | feb 8, 2022</p>
              </div>
              <div className="col-lg-6">
                    <a href="#" className="p-1">
                      <FacebookShareButton
                        url={"https://archikadidi.com"}
                        hashtag="#ArchikaDidiBlog"
                        quote={"CampersTribe - World is yours to explore"}
                      >
                        <FacebookIcon size={45} round={true} />
                      </FacebookShareButton>
                    </a>
                    {/* <a href="#" className="p-1">
                      <FacebookMessengerShareButton
                        appId=""
                        title="Share on Messenger"
                        url={url}
                        hashtag="#ArchikaDidiBlog"
                      >
                        <FacebookMessengerIcon size={45} round={true} />
                      </FacebookMessengerShareButton>
                    </a> */}
                    {/* <a href="#" className="p-1">instagram</a> */}
                    <a href="#" className="p-1">
                      <WhatsappShareButton
                        appId=""
                        title="Hi I am happy to share Blogs by Archika Didi"
                        url={url}
                        hashtag="#ArchikaDidiBlog"
                      >
                        <WhatsappIcon size={45} round={true} />
                      </WhatsappShareButton>
                    </a>
                    <a href="#" className="p-1">
                      <TwitterShareButton
                        appId=""
                        title="Hi I am happy to share Blogs by Archika Didi"
                        hashtag="#ArchikaDidiBlog"
                        url={url}
                      >
                        <XIcon size={45} round={true} />
                      </TwitterShareButton>
                    </a>
                    <a href="#" className="p-1">
                      <TelegramShareButton
                        title="Hi I am happy to share Blogs by Archika Didi"
                        url={url}
                      >
                        <TelegramIcon size={45} round={true} />
                      </TelegramShareButton>
                    </a>
                  </div>
            </div>
            <div className="contyu px-5 pt-4" />
            <p>
              Originally, brahmana, kshatriya, vaishya, and shudra were not seen
              as sects of people who are born in certain families as it is
              understood today, but as descriptions of qualifications. A shudra
              was someone who did menial jobs; all he knew was his body. A
              vaishya was someone who was using his body, and to some extent,
              his mind. He was largely free of emotion, except for his own
              family. His body and his calculations ruled his nature. A
              kshatriya was passionate to the point of involving his life
              energies. He was more in his heart; otherwise he could not rule or
              lay down his life for what he believed in..
            </p>

            {/* </div> */}
          </div>
        </div>
      </section>
      {/* text section all end */}

      <div className="tacky">
        <div className="container">
          <div className="tackuty p-5">
            <h1 className="text-center fs-1 fw-bold">
              <span style={{ fontSize: "95px" }}>All Episodes</span>
            </h1>

            <div className="elemt pt-2">
              <div className="apixoss py-4">
                <img
                  src={playbtn}
                  style={{
                    width: "50px",
                    filter: "dropShadow(0px 16px 10px rgba(0,0,225,0.6))",
                  }}
                  alt="Dr. Archika Didi"
                />
                <p className="pt-2 yera fw-semibold">
                  Finding Moments of Joy In A Challenging Year
                </p>
                <p className="pt-2" style={{ fontSize: "0.8rem" }}>
                  March 19 2021
                </p>
                <a href="" className="pt-2" style={{ fontSize: "0.8rem" }}>
                  <i className="fa-solid fa-headphones px-2"></i>FASHION
                </a>
                <a
                  href=""
                  className="btn vieww text-dark"
                  style={{ fontSize: "0.8rem" }}
                >
                  View More<i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div className="elemt pt-4">
              <div className="apixoss py-4">
                <img loading="lazy" src={playbtn} style={{ width: "50px" }} alt="Dr. Archika Didi" />
                <p className="pt-2 yera fw-semibold">
                  Finding Moments of Joy In A Challenging Year
                </p>
                <p className="pt-2" style={{ fontSize: "0.8rem" }}>
                  March 19 2021
                </p>
                <a href="" className="pt-2" style={{ fontSize: "0.8rem" }}>
                  <i className="fa-solid fa-headphones px-2"></i>FASHION
                </a>
                <a
                  href=""
                  className="btn vieww text-dark"
                  style={{ fontSize: "0.8rem" }}
                >
                  View More<i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div className="elemt pt-4 position-relative">
              <div className="apixoss py-4">
                <img loading="lazy" src={playbtn} style={{ width: "50px" }} alt="Dr. Archika Didi" />
                <p className="pt-2 yera fw-semibold">
                  Finding Moments of Joy In A Challenging Year
                </p>
                <p className="pt-2" style={{ fontSize: "0.8rem" }}>
                  March 19 2021
                </p>
                <a href="" className="pt-2" style={{ fontSize: "0.8rem" }}>
                  <i className="fa-solid fa-headphones px-2"></i>FASHION
                </a>
                <a
                  href=""
                  className="btn vieww text-dark"
                  style={{ fontSize: "0.8rem" }}
                >
                  View More<i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div className="elemt pt-4 position-relative">
              <div className="apixoss py-4">
                <img loading="lazy" src={playbtn} style={{ width: "50px" }} alt="Dr. Archika Didi" />
                <p className="pt-2 yera fw-semibold">
                  Finding Moments of Joy In A Challenging Year
                </p>
                <p className="pt-2" style={{ fontSize: "0.8rem" }}>
                  March 19 2021
                </p>
                <a href="" className="pt-2" style={{ fontSize: "0.8rem" }}>
                  <i className="fa-solid fa-headphones px-2"></i>FASHION
                </a>
                <a
                  href=""
                  className="btn vieww text-dark"
                  style={{ fontSize: "0.8rem" }}
                >
                  View More<i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div className="pt-5 text-center">
              <h3 className="fw-semibold">₹399.00</h3>
              <a href="">
                <button
                  className="btn shadowbuttons btn-lg text-light rounded cmn-t-shake "
                  style={{
                    backgroundColor: "#FF9933!important",
                    padding: "0.7rem 4.5rem",
                  }}
                >
                  BUY NOW
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessVideo;
