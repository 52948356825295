import React from 'react'
import ms from '../images/medservice.png'
import event1 from '../images/event1.jpeg'
import event2 from '../images/event2.jpg'
import event3 from '../images/event3.jpg'
import event4 from '../images/event4.jpg'
import event5 from '../images/event5.jpg'
import event6 from '../images/event6.jpg'
import event7 from '../images/event7.jpg'
import event8 from '../images/event8.jpg'
import MetaData from "../component/layout/MetaData";
const Events1 = () => {
  return (
    <>
    <MetaData title="Events 1" />
    <div className='container-fluid' style={{
          backgroundImage: `url(${ms})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}>
           

<div className="musicxr">
        <div className="container muxrty">
          <div className="row">
            <div className="col-lg-5 col-xl-5 col-md-12 col-sm-10">
              <h1 className="fw-bold text-light">
                <span style={{ fontSize: "larger" }}>Meditation retreats</span>
              </h1>
              <p className="pt-2 text-light">
                Dr. Archika Didi’s divine spirit came into existence of this
                physical and spiritual world by the grace of His Holiness Shri
                Sudhanshuji Maharaj, her father. She has been brought up with
                love and divine knowledge.
              </p>
              <button
          type="button"
          className="btn btn-lg mb-2 d-sm-5"
          style={{ backgroundColor: "#ff9933", color: "white"}}
          >
          LEARN MORE
        </button>
            </div>
            <div className="col-lg-7 col-xl-7 col-md-12`"></div>
          </div>
        </div>
      </div>
    </div>

    <section className="event_card mt-5 pt-5 pb-5 colormixsky">
        <div className="colormixsky d-none d-lg-block"></div>
        <div className="eventhead">
          <h1 className="text-center">
            <span>Featured Events</span>
          </h1>
        </div>
        <div className="container">
          <div className="row">
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event1}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />
                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn btn-primary">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event2}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn btn-danger">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event3}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn shadowbuttons">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event4}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn btn-info">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event5}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn btn-primary">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event6}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn btn-danger">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event7}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn shadowbuttons">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-md-3 col-lg-3">
              <div className="product-card mt-4">
                <div className="product-card-inner row">
                  <div className="product-card-image col">
                    <img
                      src={event8}
                      alt="Dr. Archika Didi"
                      className="w-100 position-absolute top-0 left-0 main-image"
                    />

                    <div className="w-100 h-100 product-card-overlay position-absolute top-0 left-0"></div>
                    <div className="on-image-content d-flex gap-2 text-light p-2">
                      <div className="d-flex justify-content-between gap-2 top-info"></div>
                      <div className="price-strip">
                        <p className="m-0 text-light btn btn-info">
                          Art & Craft{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="product-card-content col">
                    <span className="badge">
                      25 <br />
                      sep
                    </span>
                    <div className="p-2 pt-4 content-block position-relative">
                      <h6 className="title">Designer web Development</h6>
                    </div>
                    <div className="control px-2 text-end d-flex align-items-center">
                      <div className="pb-2">
                        <i className="fa-solid fa-location-dot"></i> New Delhi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Events1
