import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Rectangle74 from '../assets/Rectangle74.png';
import Rectangle74 from "../../../src/images/Rectangle 74.png";
import { useNavigate, useParams } from "react-router-dom";
import "./auth.css";
import { resetPassword } from "./authSlice";
import { useDispatch } from "react-redux";
import MetaData from "../../component/layout/MetaData";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../appUtils/appToast";
function NewPassword() {
  const params = useParams();
  const { token } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handlesubmitnewPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    const myForm = new FormData();

    myForm.set("password", password);
    myForm.set("confirmPassword", confirmPassword);

    // dispatch(resetPassword(token, myForm));
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      setLoading(false);
      return;
    }

    dispatch(
      resetPassword({
        token: token,
        password: password,
        confirmPassword: confirmPassword,
      })
    ).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        SHOW_SUCCESS_NOTIFICATION("New Password Updated Successfully");
        navigate("/login");
      } else {
        SHOW_ERROR_NOTIFICATION("Error !!Try Again Later");
        setLoading(false);
      }
    });
    setLoading(false);
  };

  return (
    <div>
      <MetaData title="New Password" />

      {/* Include Header component */}
      <header />

      <div
        className="hero112"
        style={{
          backgroundImage: `url(${Rectangle74})`,
          backgroundSize: "cover",
          height: "600px",
        }}
      >
        <div className="login12">
          <div className="main_div">
            <div className="text-center">
              <h1>
                <span style={{ fontSize: "40px" }} className="loginfont">New Password</span>
              </h1>
            </div>
            <form
              action="#"
              className="pt-4"
              onSubmit={handlesubmitnewPassword}
            >
              <label htmlFor="basic-url" className="form-label">
                Password
              </label>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-warning text-white rounded-0 password bgPcolor"
                  id="basic-addon1"
                >
                  <i
                    className="bx bx-lock-open-alt"
                    style={{ fontSize: "25px" }}
                  ></i>
                </span>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control rounded-0"
                  placeholder="Password"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>

              <label htmlFor="basic-url" className="form-label">
                Confirm Password
              </label>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-warning text-white rounded-0 password bgPcolor"
                  id="basic-addon1"
                >
                  <i
                    className="bx bx-lock-open-alt"
                    style={{ fontSize: "25px" }}
                  ></i>
                </span>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control rounded-0"
                  placeholder="Password"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>

              <div className="input_box button">
                {loading ? (
                  <button className="btn bgPcolor rounded-3 w-100 h-100" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                ) : (
                  <input
                    type="submit"
                    onClick={handlesubmitnewPassword}
                    className="bgPcolor rounded-3"
                    value="Submit"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Include Footer component */}
      <footer />
    </div>
  );
}

export default NewPassword;
