import axiosLib from "axios";

const axios = axiosLib.create({
  //baseURL: process.env.REACT_APP_API_HOST,
  // baseURL: 'http://localhost:5500/api/V1/',
  baseURL: `${process.env.REACT_APP_API_URL}/api/V1/`,
  // baseURL: `http://192.168.1.14:8080/api/V1/`,
});

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default axios;
