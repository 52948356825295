import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import crownimg from '../../src/images/crown.png';

const EventCards = React.memo(({ event, onClick }) => {
  const {
    title,
    slug,
    eventsSchedule,
    isPaid,
  } = event;

  const schedule = eventsSchedule[0];

  const handleClick = () => {
    onClick(slug, schedule.scheduleslug);
  };

  return (
    <div
      className="col-md-12 col-sm-12 col-lg-12"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <div
        className="card item-card text-dark mx-2 overflow-hidden cardheightevent"
        style={{ borderRadius: '10px' }}
      >
        <div className="position-relative">
          <img
            src={schedule.thumbnail_image}
            alt="Event Thumbnail"
            style={{ aspectRatio: '3/2' }}
          />
          <p
            className="position-absolute m-0"
            style={{
              background: 'linear-gradient(0deg, rgba(183,103,162,1) 35%, rgba(183,103,162,0.10696778711484589) 100%)',
              padding: '10px 0px',
              borderRadius: '12px',
              color: 'white',
              width: '100%',
              textAlign: 'center',
              bottom: '0px',
              fontSize: '28px',
              fontWeight: '500px',
            }}
          >
            {schedule.eventType}
          </p>
        </div>
        <div className="container pt-4">
          <h4
            className="mt-1 text-capitalize"
            style={{
              fontFamily: 'myFont',
              color: 'black',
              height: '45px',
            }}
          >
            {title.length > 40 ? `${title.substring(0, 40)}...` : title}
          </h4>
          <h5
            className="text-capitalize"
            style={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 1)',
              fontFamily: 'Lato',
              fontWeight: 600,
              paddingTop: '10px',
            }}
          >
            {schedule.eventType === 'Offline' ? (
              <>
                <i
                  className="fa-solid fa-location-dot text-capitalize"
                  style={{ height: '10px' }}
                ></i>{' '}
                {schedule.location.length > 35
                  ? `${schedule.location.substring(0, 35)}...`
                  : schedule.location}
              </>
            ) : (
              <div style={{ height: '10px', visibility: 'hidden' }}>Online</div>
            )}
          </h5>
          <p
            className="card-text lato text-capitalize pt-2"
            dangerouslySetInnerHTML={{
              __html:
                schedule.shortdescription.length > 300
                  ? `${schedule.shortdescription.replace(/<\/?p>/gi, '').substring(0, 300)}...`
                  : schedule.shortdescription.replace(/<\/?p>/gi, ''),
            }}
            style={{ fontFamily: 'Lato', color: '#5B5B5B' }}
          ></p>
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="btn btn-lg mb-3 d-sm-5 fs-6"
              style={{
                backgroundColor: '#B767A2',
                color: 'white',
                borderRadius: '20px',
                height: '60px',
              }}
            >
              JOIN EVENTS
            </button>
            {isPaid && (
              <img
                src={crownimg}
                style={{
                  top: '1.2rem',
                  right: '1.7rem',
                  width: '1.5rem',
                }}
                className="img-fluid position-absolute"
                alt="Premium Event"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

EventCards.propTypes = {
  event: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EventCards;
