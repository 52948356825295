import React, { useEffect, useState, useRef } from "react";
import bookimage from "../images/emotional healing book 1.png";
import backgroundimg from "../images/Rectangle 266.png";
import emotion from "../images/REprogram your mind (2) 1.png";
import programming from "../images/REprogram your mind (2) 1.png";
import { getsingleBook, getBookPage } from "../Allslices/BookSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { IoCloseSharp } from "react-icons/io5";

const BlissfullBookpage = () => {
  const { singledata } = useSelector((state) => state.bookpage);

  const { Bookdata, configdata } = useSelector((state) => state.bookpage);
  const [showModal, setShowModal] = useState(false);
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getsingleBook(slug));
    dispatch(getBookPage({ page: 0, limit: 6 }));
  }, [dispatch, slug]);

  useEffect(() => {
    if (singledata?.coming_soon) {
      navigate("/books");
    }
  }, [singledata]);

  const navigate = useNavigate();
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const blissfullpage = (item) => {
    if (item?.coming_soon == true) {
      handleShow();
    } else {
      navigate(`/book/${item.slug}`);
    }
  };
  const maxLength = 40;

  const percentageDecrease =
    ((singledata?.mrp - singledata?.sellPrice) / singledata?.mrp) * 100;
  const percentage = Math.floor(percentageDecrease);

  const calculateSavingsPercentage = (mrp, sellPrice) => {
    if (!mrp || mrp === 0) return 0; // Prevent division by zero
    return ((mrp - sellPrice) / mrp) * 100;
  };

  const newbookdata = Bookdata?.filter((item) => item.slug != slug);
  const newrating = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= singledata?.rating) {
      newrating.push(
        <i
          className="fa-solid fa-star"
          key={i}
          style={{ color: "#B767A2" }}
        ></i>
      );
    } else {
      newrating.push(
        <i className="fa-regular fa-star" key={i} style={{ color: "gray" }}></i>
      );
    }
  }
  let sliderRef1 = useRef();
  const numCards = newbookdata?.length || 0;
  const minSlidesToShow = Math.min(3, numCards);
  const settings1 = {
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  settings1.slidesToShow = minSlidesToShow;
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          right: "-15px!important",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowRight color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          left: "-35px",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowLeft color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  return (
    <div>
      <div>
        <div
          className="container-fluid "
          style={{
            backgroundImage: `url(${backgroundimg})`,
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12  d-flex justify-content-center mt-5">
              <img
                src={singledata?.thumbnail_image}
                alt="Dr. Archika Didi"
                style={{ height: "auto" }}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 col-12 mt-5 px-5"
              style={{ lineHeight: "28px" }}
            >
              <h1 style={{ fontSize: "25px", fontFamily: "myFont" }}>
                {singledata?.title}
              </h1>
              <div className="content_here">
                <p style={{ fontSize: "16px", fontFamily: "Lato" }}>
                  By {singledata?.author}
                  <br />
                  Rating {newrating}
                  <br />
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    {" "}
                    Rs. {singledata?.mrp}
                  </span>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rs. {singledata?.sellPrice}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save {percentage}%
                  <br />
                  Binding: {singledata?.bindingType}
                  <br />
                  Condition: {singledata?.condition}
                </p>

                <div className="bookbutton ">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={singledata?.buylink}
                    className="btn btn-lg fs-6 w-100 shadow rounded-pill shadowbuttons text-white"
                  >
                    Buy Now from Our Exclusive Store
                  </a>

                  {/* <a
                    href={singledata?.buylink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg fs-6 w-50 shadow rounded-pill "
                    style={{ fontFamily: "Lato" }}
                  >
                    ADD TO CART
                  </a> */}
                </div>

                <p
                  className="pt-3"
                  style={{ fontSize: "16px", fontFamily: "Lato" }}
                >
                  Delivery with {singledata?.deliveryDays}-
                  {singledata?.deliveryDays + 2} business days
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="continer-fluid"
          style={{
            overflow: "hidden",
          }}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6-col-sm-12 col-12"></div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
              <div className="accordion px-3" id="accordionExample">
                <div
                  className="accordion-item border-1 w-75 mx-auto bookwidthsize"
                  style={{ borderRadius: "27px" }}
                >
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={`accordion-button rounded-pill mybookhover border-1 ${
                        isOpen ? "" : "collapsed"
                      }`} 
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded={isOpen ? "true" : "false"}
                      aria-controls="collapseOne"
                      onClick={toggleAccordion}
                    >
                      Description
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse ${
                      isOpen ? "show" : ""
                    }`}
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p
                        className="singlepagefont"
                        style={{ color: "#807F7F", alignItems: "justify" }}
                        dangerouslySetInnerHTML={{
                          __html: singledata?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1
          className=" text-center mt-3"
          style={{ fontFamily: "myfont", fontSize: "30px" }}
        >
          Recommended Books
        </h1>

        <div className="container mt-3 mb-5 bookcontainerheight ">
          <div
            className={`row  ${
              newbookdata?.length <= 2 && "sliderscontainerBooks"
            }`}
          >
            <Slider
              ref={(slider) => {
                sliderRef1 = slider;
              }}
              {...settings1}
            >
              {Array.isArray(newbookdata) &&
                newbookdata.map((item, index) => {
                  const bookreting = [];
                  for (let i = 1; i <= 5; i++) {
                    if (i <= item?.rating) {
                      bookreting.push(
                        <i
                          className="fa-solid fa-star"
                          key={i}
                          style={{ color: "#B767A2" }}
                        ></i>
                      );
                    } else {
                      bookreting.push(
                        <i
                          className="fa-regular fa-star"
                          key={i}
                          style={{ color: "gray" }}
                        ></i>
                      );
                    }
                  }

                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 col-12"
                      key={index}
                    >
                      <div
                        className="card item-card text-dark overflow-hidden mx-2"
                        onClick={() => blissfullpage(item)}
                      >
                        <div className="blissImg1">
                          <img
                            src={item?.thumbnail_image}
                            alt="Dr. Archika Didi"
                            style={{ width: " -webkit-fill-available" }}
                          />
                        </div>
                        <div className="content_here commingsoon-content-height px-3">
                          {item?.coming_soon === true ? (
                            <h1
                              style={{
                                fontSize: "25px",
                                fontFamily: "myFont",
                                paddingTop: "25px",
                                paddingBottom: "25px",
                              }}
                            >
                              {item.title}
                            </h1>
                          ) : (
                            <>
                              <h1
                                style={{
                                  fontSize: "25px",
                                  fontFamily: "myFont",
                                }}
                              >
                                {item.title.length > maxLength
                                  ? `${item.title.substring(0, maxLength)}...`
                                  : item.title}
                              </h1>
                              <p
                                style={{ fontSize: "16px", fontFamily: "Lato" }}
                              >
                                By {item?.author}
                                <br />
                                (Rating By Good Reads) {bookreting}
                                <br />
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    fontFamily: "Lato",
                                  }}
                                >
                                  {" "}
                                  Rs. {item?.mrp}
                                </span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rs.{" "}
                                {item?.sellPrice}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save{" "}
                                {Math.floor(
                                  calculateSavingsPercentage(
                                    item?.mrp,
                                    item?.sellPrice
                                  )
                                )}{" "}
                                % <br />
                                Binding: {item?.bindingType}
                                <br />
                                {/* Condition: {item?.condition} */}
                                <br />
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ fontFamily: "myFont", color: "#9767a2" }}
              >
                Coming Soon
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                style={{ color: "#9767a2" }}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato pb-4 ">
              {/* <img loading="lazy" src={coming_soon_image} alt="Dr. Archika Didi" height={10} width={100}/> */}
              This book is coming soon. Please check back later!
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn shadowbuttons text-white fs-6" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlissfullBookpage;
